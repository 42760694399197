<div class="star_rating_container flex_column_center_between br_16">
  <div class="flex_center_align_center">
    <div class="text_title bold_800 text_center">
      {{'star_rating.title' | translate}}  №{{orderData.orderId}} {{'star_rating.title2' | translate}}
    </div>
    <div class="clear_button" *ngIf="!isMobile">
      <app-clear-button (closeButton)="closeDialog()"></app-clear-button>
    </div>
  </div>
  <div class="description text_center bold text_title_middle">
    {{'star_rating.description' | translate}}
  </div>
  <div class="stars_container w_100">
    <app-stars
      [title]="'star_rating.price_quality'"
      (selectedStarEmitter)="enableSaveAndGetValue($event,starRateEnum.PRICE_QUALITY)"
    ></app-stars>
    <app-stars
      [title]="'star_rating.delivery'"
      (selectedStarEmitter)="enableSaveAndGetValue($event,starRateEnum.DELIVERY)"
    ></app-stars>
    <app-stars
      [title]="'star_rating.service'"
      (selectedStarEmitter)="enableSaveAndGetValue($event,starRateEnum.SERVICE)"
    ></app-stars>
    <div class="app_input" [formGroup]="formData">
      <app-input [inputData]="{
                            type: 'input',
                            label: 'star_rating.feedback_on_the_order',
                            outputValue:true,
                            data: {
                              key:'comment',
                              value: formData.controls['comment'].value
                             },
                            placeholder: 'star_rating.feedback_on_the_order'}"
                 (emitValue)="handleInputChange($event)"
      >
      </app-input>
    </div>

  </div>

  <button class="main_buttons w_100 big_btn mt_18 flex_center_align_center"
          [ngClass]="{black_btn: !saveButtonDisableState, gray_btn: saveButtonDisableState}"
          [disabled]="saveButtonDisableState"
          (click)="sendRate()"
  >
    {{'star_rating.ready_button'| translate}}
  </button>

  <div class="skip flex_center_align_center text_center bold font_13">
    <span (click)="closeDialog()" class="pointer">
      {{'star_rating.no_thanks_skip'| translate}}
    </span>
    <img src="assets/images/icons/rating/arrow_right.svg" class="ml_2">
  </div>
</div>

