import {afterNextRender, Inject, Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {DOCUMENT} from "@angular/common";
//components
import {LoginComponent} from "../../modules/auth/login/login.component";
import {MatDialog} from "@angular/material/dialog";
//constants
import {CheckToken, TokenInterface} from "@interfaces/auth/login.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {UsersInterface} from "@interfaces/components/users.interface";
//services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {BrowserService} from "@services/components/browser.service";
import {BasketService} from "@services/basket/basket.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user!: TokenInterface | null;
  tokenNameInLocalStorage = 'AuthTokenRetail';
  userInfoNameInLocalStorage = 'UserInfo';

  LocalStorage!: any;

  constructor(
    private requestService: RequestMethodsService,
    private router: Router,
    private dialog: MatDialog,
    private globalSubscriptionService: GlobalSubscriptionService,
    private browserService: BrowserService,
    private basketService: BasketService,
    @Inject(DOCUMENT) private document: Document
  ) {
    afterNextRender(() => {
      this.LocalStorage = document.defaultView?.localStorage;
      this.user = this.getTokenFromLocalStorage();
    })
  }

  setToken(response: TokenInterface): void {
    this.user = response;
    this.LocalStorage.setItem(this.tokenNameInLocalStorage, JSON.stringify(response));
    this.getUserInfoAndSaveInLocalStorage();
  }

  getTokenFromLocalStorage(): TokenInterface | null {
    const user = this.LocalStorage.getItem(this.tokenNameInLocalStorage);
    return user ? JSON.parse(user) : null;
  }

  getUser(): TokenInterface | null {
    if (this.user) {
      return this.user;
    }
    const storedToken = this.LocalStorage?.getItem(this.tokenNameInLocalStorage);
    if (storedToken) {
      this.user = JSON.parse(storedToken); // Set the user from LocalStorage
      return this.user;
    }
    return null;
  }

  getUserInfoAndSaveInLocalStorage(reloadPage = true): void {
    this.requestService.get(`${EndpointConstant.users}/${EndpointConstant.self}`).subscribe((res: UsersInterface) => {
      this.LocalStorage.setItem(this.userInfoNameInLocalStorage, JSON.stringify(res));
      if (reloadPage) {
        window.location.href = '/products';
        this.basketService.clearBasket().subscribe(() => {
        });
      } else {
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_PROFILE)
      }
    })
  }

  getUserInfo(): UsersInterface | null {
    const userInfo = this.LocalStorage?.getItem(this.userInfoNameInLocalStorage);
    return userInfo ? JSON.parse(userInfo) : null;
  }

  logout(): void {
    this.user = null;
    this.LocalStorage.clear();
    setTimeout(() => {
      window.location.href = '/products';
    })
  }

  checkToken(): CheckToken {
    return this.user?.accessToken ? CheckToken.VALID : CheckToken.INVALID;
  }

  tryToRefreshToken(): Observable<any> {
    return this.requestService.get(EndpointConstant.refreshToken);
  }

  checkTokenAndRedirectToAuth(): boolean {
    const isUser = (this.checkToken() === CheckToken.VALID);
    if (!isUser) {
      // this.router.navigate(['/']);
      if (!this.browserService.isMobileDevice) {
        this.dialog.open(LoginComponent, {
          width: "400px"
        });
      } else {
        this.router.navigate(['/login']);
      }
    }
    return isUser;
  }
}
