import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {Subscription} from "rxjs";
import {TranslateModule} from '@ngx-translate/core';
import {NgClass, NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, TranslateModule, NgIf]
})
export class FooterComponent {
  subject!: Subscription;
  add_big_padding!: boolean;
  downloadLinksConfig = [
    {
      link: "https://apps.apple.com/ru/app/click-market/id1662031300",
      image: "assets/images/icons/app_sore.svg"
    },
    {
      link: "https://play.google.com/store/apps/details?id=com.tech_yan.click_market_retail",
      image: "assets/images/icons/google_play.svg"
    },
    {
      link: "https://appgallery.huawei.com/app/C107808083",
      image: "assets/images/icons/huawei.svg"
    }
  ]

  socialLinks = [
    {
      link: "https://wa.me/79674609540",
      image: "assets/images/icons/footer/wp.svg"
    },
    {
      link: "https://t.me/clickmarketsupport",
      image: "assets/images/icons/footer/telegram.svg"
    },
    {
      link: "https://vk.com/clickmarket",
      image: "assets/images/icons/footer/vk.svg"
    }
  ]


  constructor(private router: Router, private globalSubscriptionService: GlobalSubscriptionService) {
    this.subject = this.globalSubscriptionService.getMessage()
      .subscribe((data: { type: string, message: boolean }) => {
        if (data.type === UpdateSubscriptionTypesEnum.ADD_BOTTOM_BASKET) {
          this.add_big_padding = data.message;
        }
      })
  }

  downloadApplicationLink(link: string): void {
    window.open(link, '_blank')
  }

  navigateTpSocialLink(link: string): void {
    window.open(link, '_blank')
  }

  navigateToPrivacyPolicyPage(): void {
    window.location.href = '/privacy-policy';
  }

  navigateToPrivacyTermsOfUse(): void {
    window.location.href = '/terms-of-use';
  }
}
