import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf, NgStyle} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
//Constants
import {EmptyPageSettingsInterface} from "@interfaces/components/empty-page-settings.interface";

@Component({
  selector: 'app-empty-page',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    NgStyle
  ],
  templateUrl: './empty-page.component.html',
  styleUrl: './empty-page.component.scss'
})
export class EmptyPageComponent {
  @Input() settings!: EmptyPageSettingsInterface;
  @Output() outputDataEvent = new EventEmitter<any>();
}
