import {Component, Input} from '@angular/core';
import {DecimalPipe, NgClass, NgFor, NgIf} from "@angular/common";
import {GlobalModule} from "../global.module";
//Pipes
import {RateCountPipe} from "../../../pipes/string-methods/rate-count.pipe";
import {ReplaceToCommaPipe} from "../../../pipes/string-methods/replace-to-comma.pipe";

@Component({
  selector: 'app-star-rate',
  standalone: true,
  imports: [
    DecimalPipe,
    NgClass,
    NgFor,
    RateCountPipe,
    NgIf,
    GlobalModule,
    ReplaceToCommaPipe
  ],
  templateUrl: './star-rate.component.html',
  styleUrl: './star-rate.component.scss'
})
export class StarRateComponent {
  @Input() rateCount!: number;
  @Input() rate!: number;
}
