import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.scss'],
    standalone: true,
    imports: [NgFor, TranslateModule]
})
export class StarsComponent {
  @Input() title!: string;
  @Output() selectedStarEmitter = new EventEmitter<any>();
  selectedIndex: number | null = null;

  rate(starIndex: number): void {
    if (starIndex == this.selectedIndex) {
      this.selectedIndex = null;
      this.selectedStarEmitter.emit(this.selectedIndex)
      return;
    }
    this.selectedIndex = starIndex;
    this.selectedStarEmitter.emit(this.selectedIndex+1)
  }

}
