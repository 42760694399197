import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
// Constants
import {ProductListInterface} from "@interfaces/catalog/products.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {AddAndReduceStatusEnum} from "@interfaces/components/enums/add-and-reduce-status.enum";
// Services
import {BasketService} from "@services/basket/basket.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";

@Component({
  selector: 'app-add-and-reduce',
  templateUrl: './add-and-reduce.component.html',
  styleUrls: ['./add-and-reduce.component.scss'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class AddAndReduceComponent {
  @Input() productCard!: ProductListInterface;
  @Input() type!: AddAndReduceStatusEnum;
  addAndReduceStatusEnum=AddAndReduceStatusEnum;
  pending = false;

  constructor(
    private basketService: BasketService,
    private globalSubscriptionService: GlobalSubscriptionService
  ) {
  }

  checkAndEmitEventByTypeOfBasket(): void {
    if (!this.type) {
      return;
    }

    if ([AddAndReduceStatusEnum.GENERAL, AddAndReduceStatusEnum.SINGLE_PAGE].includes(this.type)) {
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET);
    } else if (this.type === AddAndReduceStatusEnum.ORDER_CREATION) {
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET_FROM_ORDERS);
    }
  }

  addCount(): void {
    if (!this.productCard.countInBasket || this.pending) {
      return;
    }
    this.productCard.countInBasket++;
    this.pending = true;
    this.basketService.addToBasket({
      productShopId: this.productCard.id,
      count: this.productCard.countInBasket
    }).subscribe(() => {
      this.checkAndEmitEventByTypeOfBasket()
      this.pending = false;
    }, () => this.pending = false);

  }

  reduceCount(): void {
    if ((!this.productCard.countInBasket || this.productCard.countInBasket < 1) || this.pending) {
      return;
    }

    this.productCard.countInBasket--;

    const request = (this.productCard.countInBasket === 0) ? this.basketService.deleteBasketItems(this.productCard.id)
      : this.basketService.addToBasket({productShopId: this.productCard.id, count: this.productCard.countInBasket});

    this.pending = true;
    request.subscribe(() => {
      this.checkAndEmitEventByTypeOfBasket()
      this.pending = false;
    }, () => this.pending = false)
  }

  deleteFromBasket(): void {
    this.pending = true;
    this.productCard.countInBasket = 0;
    this.basketService.deleteBasketItems(this.productCard.id).subscribe(() => {
      this.checkAndEmitEventByTypeOfBasket()
      this.pending = false;
    }, () => this.pending = false);
  }

}
