export const SuccessMessages: { [key: string]: string } = {
  login: 'Successfully Signed In',
  shopAssigned: 'Магазин прикреплён к заказу',
  statusChanged: 'Статус успешно обновлен',
  deliveryOn: 'Дата доставки обновлён',
  deliveryAddressChange: 'Адрес доставки обновлён',
  copied: 'Ссылка успешно скопирована.',
  copy_promo: 'Промокод успешно скопирована.'
};

export const ErrorMessages: { [key: string]: string } = {
  global: 'Sorry something went wrong',
  contractNotFound: 'Contract number doesnt exists',
  rolePermissions: 'Permissions cannot be empty',
  noRoutesFound: 'No routes parsed from file.',
  // Contract Frequency Creation
  noMatchedRoutesForContracts: 'No matched routes for selected contracts',
  pleaseSelectContractsForNextStep: 'Select contracts for next step',
  rulesRequired: 'Please add rules before saving',
  selectFrequencyRequired: 'Please select a frequency',
  saveRule: 'Save added rule',
  frequencyCodeAlreadyExistsInContract: 'Frequency code already exists in current contract',
  contractLocationAlreadyExists: 'Location already exists in current contract',
  selectRowBeforeNext: 'Select one of rows before next step',
  // End of Contract Frequency Creation
  tripAlreadyAssigned: 'Trip already assigned',
  subLegIsCompleted: 'Trip sub leg is completed',
  tripIsNotAssigned: 'Trip is not assigned',
  tripUnAssignNotAvailable: 'Trip unassign not available',
  tripAssignNotAvailable: 'Trip assignment or update not available',
  tripAssignNotAllowed: 'Trip assignment not allowed',
  tripAssignUpdateNotAllowed: 'Trip assignment update not allowed',
  minLengthOrCapacity: 'Minimum Length or Minimum Capacity is required',
  // Contract Route creation
  pageCanNotBiggerThenTotal: 'Entered page can\'t bigger than total pages',
  stopsRequired: 'Stop count must be equal to or greater than 2',
  legsRequired: 'Leg is required',
  noAvailableDrivers: 'No Available Drivers',
  originDestinationCantBeSplitAsLegStart: 'Origin or Destination can`t be set as start of leg',
  noAvailableUnits: 'No Available Units',
  selectWeeklySchedules: 'Please select weekly schedules',
  selectRow: 'Please select row',
  permissionError: 'You are not allowed here!',
  notFound: 'Not Found',
  resetBasketBeforeAdd: 'Очистите корзину до добавления товара.'
};
