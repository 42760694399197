import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {NgClass, NgIf} from "@angular/common";

//Components
import {NavbarComponent} from "../navbar/navbar.component";
import {TopNavbarComponent} from "./top-navbar/top-navbar.component";
import {BottomNavbarComponent} from "./bottom-navbar/bottom-navbar.component";

//Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {CityService} from "@services/city/city.service";
import {ComponentService} from "@services/components/component.service";
import {BrowserService} from "@services/components/browser.service";
import {BottomSheetDialogService} from "@services/bottom-sheet-dialog/bottom-sheet-dialog.service";
import {AuthService} from "@services/auth/auth.service";
import {IsInGeofenceService} from "@services/is-in-geofence/is-in-geofence.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";
import {BasketService} from "@services/basket/basket.service";

//Constants
import {NavigationBackButtonTypeEnum} from "@interfaces/components/enums/navigation-back-button-type.enum";
import {
  ShowDefaultAddressToChangeComponent
} from "../navbar/top-navbar/show-default-address-to-change/show-default-address-to-change.component";
import {MobileMapComponent} from "../address-selection/mobile-map/mobile-map.component";

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  standalone: true,
    imports: [NgIf, NgClass, RouterLink, TopNavbarComponent, BottomNavbarComponent, ShowDefaultAddressToChangeComponent, MobileMapComponent]
})
export class MobileNavbarComponent extends NavbarComponent {
  navigationIcon: NavigationBackButtonTypeEnum = NavigationBackButtonTypeEnum.BACK;
  navigationBackButtonTypeEnum = NavigationBackButtonTypeEnum;

  constructor(
    public override requestService: RequestMethodsService,
    public override globalSubscriptionService: GlobalSubscriptionService,
    public override cityService: CityService,
    public override activatedRoute: ActivatedRoute,
    public override componentService: ComponentService,
    public override browserService: BrowserService,
    public override dialog: MatDialog,
    public override bottomSheetService: BottomSheetDialogService,
    public override authService: AuthService,
    public override router: Router,
    public override basketService: BasketService,
    public override confirmationDialogService: ConfirmationDialogService,
    public override mapService: IsInGeofenceService,
  ) {
    super(requestService,
      globalSubscriptionService, cityService, activatedRoute,
      componentService, browserService, dialog, bottomSheetService,
      authService, router, basketService, confirmationDialogService, mapService
    )
    this.checkRoute()
  }

  handleDialogClose(openAddressSelection: boolean): void {
    this.openDialogOrBottomSheetAddressSelect(openAddressSelection);
  }

  checkRoute(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url === '/products') {
          this.navigationIcon = NavigationBackButtonTypeEnum.CATALOG;
        } else if (val.url === '/order-creation') {
          this.navigationIcon = NavigationBackButtonTypeEnum.ORDER_CREATION;
        } else if(val.url === '/select-address'){
          this.navigationIcon = NavigationBackButtonTypeEnum.MOBILE_MAP;
        } else {
          this.navigationIcon = NavigationBackButtonTypeEnum.BACK;
        }
      }
    });
  }
}
