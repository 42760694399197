import {afterNextRender, Injectable} from '@angular/core';
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IsInGeofenceService {
  ymaps!: any;

  constructor() {
    afterNextRender(() => {
      this.ymaps = (window as any)['ymaps'];
    })
  }

  fromGeObjectGetLongLatAndAddress(
    name: any,
    latLong: Array<number>,
    cityName: string
  ): FullAddressLongLatInterface | null {

    return {
      fullAddress: name,
      longitude: +latLong[1],
      latitude: +latLong[0],
      cityName
    }
  }

  getCityName(latitude: number, longitude: number): Observable<string> {
    return new Observable<string>((observer) => {
      this.ymaps.geocode([latitude, longitude], {
        kind: 'locality',
        results: 1
      }).then((result: any) => {
        const geoObject = result.geoObjects.get(0);
        if (geoObject) {
          const city = geoObject.getLocalities()[0];
          observer.next(city);
          observer.complete();
        } else {
          observer.error('No locality found');
        }
      }).catch((error: any) => {
        observer.error(error);
      });
    });
  }
}
