import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchInString',
  standalone: true
})
export class SearchInStringPipe implements PipeTransform {

  transform(
    firstString: string,
    secondString: string
  ): string {


    const str1 = firstString.toLowerCase().trim();
    const str2 = secondString.toLowerCase().trim();
    const index = str2.indexOf(str1);
    if (index !== -1) {
      const beforeMatch = secondString.substring(0, index);
      const match = secondString.substring(index, index + str1.length);
      const afterMatch = secondString.substring(index + str1.length);
      return `${beforeMatch}<span class="bold_800">${match}</span>${afterMatch}`;
    }

    // const words = str1.split(' ').filter(word => word.trim().length > 0);
    // let highlightedString = secondString;
    //
    // words.forEach(word => {
    //   const regex = new RegExp(`(${word})`, 'gi');
    //   highlightedString = highlightedString.replace(regex, '<span class="bold_800">$1</span>');
    // });

    return secondString;
  }


}
