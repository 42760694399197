import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
//constants
import {AppInputInterface} from "@interfaces/orders/app-input.interface";
//services
import {BrowserService} from "@services/components/browser.service";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgxMaskDirective, FormsModule, TranslateModule]
})
export class InputComponent implements AfterViewInit {
  @Input() inputData!: AppInputInterface;
  @ViewChild('input') input!: ElementRef;
  @Output() emitValue = new EventEmitter<string>();
  @Output() emitOpenConfirmationDialog = new EventEmitter<boolean>();
  isMobile!: Boolean

  constructor(private browserService: BrowserService) {
    this.isMobile = this.browserService.isMobileDevice;
  }

  ngAfterViewInit(): void {
    if (this.inputData.outputValue) {
      this.input.nativeElement.addEventListener('input', (event: any) => {
        this.emitValue.emit(event.target.value)
      })
    }
  }
  clearInputField(event:any): void {
    event.stopPropagation();
    if (!this.inputData.dontResetValueInClearAction) {
     this.input.nativeElement.value = null;
    }
    this.emitOpenConfirmationDialog.emit(true);
  }
}
