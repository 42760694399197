import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {Router} from "@angular/router";
import {ReCaptchaV3Service} from "ng-recaptcha";
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
    selector: 'app-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgxMaskDirective, NgClass, TranslateModule]
})
export class PhoneNumberComponent implements OnDestroy, AfterViewInit {
  @Input() set phoneNumber(phone: string) {
    if (!phone) {
      return;
    }
    this.form.patchValue({phone, checked: true})
  };

  @Output() sendCodeToPhoneEvent = new EventEmitter<{ phone: string, disable: boolean, recaptchaToken: string }>();

  constructor(
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}
  recaptchaToken = '';
  submit!: boolean;
  form = new FormGroup({
    phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]+")]),
    checked: new FormControl(false, [Validators.requiredTrue])
  });
  isSended = false;

  ngAfterViewInit(): void {
    this.hiedOrShowRecaptcha(false);
  }

  phoneLogIn(): void {
    this.submit = true
    if (this.form.invalid) {
      return;
    }
    this.isSended = true;
    this.recaptchaV3Service.execute('LOGIN')
      .subscribe((token: string) => {
        this.recaptchaToken = token;
        this.emitDataTOParentComponent();
      });
  }

  emitDataTOParentComponent(): void {
    if (this.form.invalid) { return; }
    this.sendCodeToPhoneEvent.emit({
      phone: this.form.value.phone || '',
      disable: this.submit,
      recaptchaToken: this.recaptchaToken
    })
  }

  openPrivacyPolicy(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/privacy-policy'])
    );
    // window.open(url, '_blank');
  }

  hiedOrShowRecaptcha(hide = true): void {
    const el = (document.querySelector('.grecaptcha-badge') as HTMLElement);
    if (el) {
      (document.querySelector('.grecaptcha-badge') as HTMLElement).style.display = hide ? 'none' : 'unset'
    }
  }

  ngOnDestroy(): void {
    this.hiedOrShowRecaptcha();
  }
}
