import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-small-screen',
    templateUrl: './small-screen.component.html',
    styleUrls: ['./small-screen.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class SmallScreenComponent {

  openDownloadLink(): void {
    // window.open('https://onelink.to/7mggrd', '_blank');
  }

}
