import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ProductGalleryComponent } from '../../product-gallery/product-gallery.component';
import { VerticalSwiperComponent } from '../vertical-swiper/vertical-swiper.component';
import { ClearButtonComponent } from '../../../global/clear-button/clear-button.component';

@Component({
    selector: 'app-images-dialog',
    templateUrl: './images-dialog.component.html',
    styleUrls: ['./images-dialog.component.scss'],
    standalone: true,
    imports: [ClearButtonComponent, VerticalSwiperComponent, ProductGalleryComponent]
})
export class ImagesDialogComponent {
  constructor(
    public dialogRef :MatDialogRef<ImagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}


  close(): void {
    this.dialogRef.close()
  }
}
