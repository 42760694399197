import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ComponentService} from "@services/components/component.service";
import { TranslateModule } from '@ngx-translate/core';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-mobile-privacy',
    templateUrl: './mobile-privacy.component.html',
    styleUrls: ['./mobile-privacy.component.scss'],
    standalone: true,
    imports: [NgIf, PersonalDataComponent, TermsOfUseComponent, TranslateModule]
})
export class MobilePrivacyComponent implements AfterViewInit{
  title!: string | null;

  constructor(private activatedRoute: ActivatedRoute , private componentService: ComponentService) {
    this.title = this.activatedRoute.snapshot.paramMap.get('title')
  }
  ngAfterViewInit(): void {
    this.componentService.scrollToTop();
  }
}
