import {AfterViewInit, Component} from '@angular/core';
//services
import {ComponentService} from "@services/components/component.service";
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from '../login/login.component';

@Component({
    selector: 'app-mobile-login',
    templateUrl: './mobile-login.component.html',
    styleUrls: ['./mobile-login.component.scss'],
    standalone: true,
    imports: [LoginComponent, TranslateModule]
})
export class MobileLoginComponent implements AfterViewInit {
  constructor(private componentService: ComponentService) {
  }
  ngAfterViewInit(): void {
    this.componentService.scrollToTop()
  }
}
