export enum UpdateSubscriptionTypesEnum {
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_CATALOG = 'UPDATE_CATALOG',
  UPDATE_NAVBAR = 'UPDATE_NAVBAR',
  UPDATE_BASKET = 'UPDATE_BASKET',
  UPDATE_ORDERS = 'UPDATE_ORDERS',
  UPDATE_BASKET_FROM_ORDERS = 'UPDATE_BASKET_FROM_ORDERS',
  CITY_SELECTION = 'CITY_SELECTION',
  UPDATE_FAVORITES = 'UPDATE_FAVORITES',
  ADD_BOTTOM_BASKET = 'ADD_BOTTOM_BASKET',
  NAVIGATE_BACK_BUTTON_EMIT = 'NAVIGATE_BACK_BUTTON_EMIT',
  MOBILE_MAP_EMIT = 'MOBILE_MAP_EMIT',
  BACK_TO_CATALOG = 'BACK_TO_CATALOG',
  OPEN_ADDRESS_SELECTION = 'OPEN_ADDRESS_SELECTION',

}
