import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
//components
import {
  ConfirmationDialogComponent
} from "../../partials/shared-modules/global/notification-dialog/confirmation-dialog.component";
//constants
import {ConfirmationDialogInterface} from "@interfaces/confirmtion-dialog/confirmation-dialog.interface";

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  openConfirmationDialog(data: ConfirmationDialogInterface): MatDialogRef<any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data
    })
  }
}
