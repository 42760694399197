import {Injectable} from '@angular/core';
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {map, Observable} from "rxjs";

// Services
import {RequestMethodsService} from "@services/request/request-methods.service";

// Interfaces
import {CityService} from "@services/city/city.service";
import {BasketItemInterface} from "@interfaces/components/basket.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {CityInterface} from "@interfaces/components/cities.interface";

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  basketData!: Array<BasketItemInterface>;
  lastShopId: number | null = null;

  constructor(
    private requestService: RequestMethodsService,
    protected cityService: CityService
  ) {
  }

  getBasket(): Observable<any> {
    const data = this.cityService.getCityIdFromLocalStorage();
    let query = '';
    if (data) {
      if ('address' in data) {
        query = data.address.latitude ? `?longitude=${data.address.longitude}&latitude=${data.address.latitude}` : ''
      } else {
        query = `?cityId=${data.city.id}`
      }
    }
    return this.requestService.get(`${EndpointConstant.basket}/${EndpointConstant.all}${query}`).pipe(map((res) => {
      this.basketData = res.basketItems
      if (this.basketData.length) {
        this.lastShopId = this.basketData[0].productShop.shop.id;
      } else {
        this.lastShopId = null
      }
      return res
    }));
  }

  clearBasket(): Observable<any> {
    return this.requestService.delete(`${EndpointConstant.basket}/${EndpointConstant.all}`);
  }

  deleteBasketItems(productId: number): Observable<any> {
    return this.requestService.delete(`${EndpointConstant.basket}/${productId}`)
  }

  addBasketItems(productId: number, shopId: number): Observable<any> {
    this.lastShopId = shopId;
    return this.requestService.post(`${EndpointConstant.basket}`, {productShopId: productId});
  }

  addToBasket(data: { productShopId: number, count: number }): Observable<any> {
    return this.requestService.put(`${EndpointConstant.basket}`, data);
  }

  checkBasketForShop(shopId: number): boolean {
    if (!this.lastShopId) {
      return true;
    }
    return (shopId === this.lastShopId);
  }

  checkBasketAvailabilityForNewAddress(
    address: { address: FavoriteAddressInterface } | { city: CityInterface }
  ): Observable<any> {
    const objectToRequest: any = {};
    if ('city' in address) {
      objectToRequest.cityId = address.city.id;
    } else {
      objectToRequest.latitude = address.address.latitude;
      objectToRequest.longitude = address.address.longitude;
    }

    return this.requestService.post(
      `${EndpointConstant.basket}/${EndpointConstant.checkAvailability}`,
      objectToRequest
    )
  }

}
