import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, NgClass, NgFor, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {Subscription} from "rxjs";
//Services
import {SwiperService} from "@services/components/swiper.service";
import {CityService} from "@services/city/city.service";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
//Constants
import {ProductSimpleInterface,} from "@interfaces/catalog/products.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {CardEnum} from "@interfaces/catalog/product-card.enum";
import {CityOrAddressSelectionInterface,} from "@interfaces/catalog/filtration-default-state.const";
import {SwiperTypeEnum} from "@interfaces/components/enums/swiper-type.enum";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
//Components
import {CardComponent} from "../../product-card/product-card.component";
import {CustomSliderComponent} from "../../../global/custom-slider/custom-slider.component";

@Component({
  selector: 'app-popular-products',
  standalone: true,
  imports: [
      NgIf,
      NgFor,
      TranslateModule,
      NgClass,
      CardComponent,
      CustomSliderComponent
      ],
  templateUrl: './popular-products.component.html',
  styleUrl: './popular-products.component.scss'
})
export class PopularProductsComponent implements OnInit {
  @Input() productId!: number;
  @Input() componentType!: CardEnum;
  @Input() isMobile!: boolean;
  cityOrAddress!: CityOrAddressSelectionInterface;
  popularProducts!: Array<ProductSimpleInterface>;
  productCardTypeEnum = CardEnum;
  subscription!: Subscription;

  constructor(
    private requestService: RequestMethodsService,
    private swiperService: SwiperService,
    private cityService: CityService,
    private globalSubscriptionService: GlobalSubscriptionService,
    @Inject(PLATFORM_ID) private platformId: any

  ) {}

  ngOnInit() {
    const cityOrAddressObject = this.cityService.getObjectCityOrAddress();
    if (!cityOrAddressObject) {
      return;
    }
    this.cityOrAddress = cityOrAddressObject;
    this.getPopularProducts();

    if (isPlatformBrowser(this.platformId)) {
      this.subscription = this.globalSubscriptionService.getMessage()
        .subscribe((data: { type: string, message: boolean }) => {
          if (data.type === UpdateSubscriptionTypesEnum.UPDATE_CATALOG) {
            this.getPopularProducts();
          }
        })
    }

  }

  getPopularProducts(): void {
    const request = this.productId ?
      this.requestService.get(`${EndpointConstant.products}/${this.productId}/${EndpointConstant.other}`) :
      this.requestService.post(`${EndpointConstant.products}/${EndpointConstant.popular}`, this.cityOrAddress);

    request.subscribe((response: Array<ProductSimpleInterface>) => {
      this.popularProducts = response;
      this.setSwiper();
    }, (error) => console.log(error))
  }

  setSwiper(): void {
    setTimeout(() => {
      this.swiperService.makeHorizontalSwipe(SwiperTypeEnum.PRODUCT_CARD, false);
    }, 2000)
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
