import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <div [hidden]="!loading" class="big_loading">
      <img src="assets/images/icons/loader.svg"/>
    </div>
  `,
  styles: [`
    .big_loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      & img {
        width: 100px;
        height: 100px;
      }
    }
  `],
  standalone: true
})
export class LoadingComponent implements OnInit {
  @Input() loading = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
