import {AfterViewInit, Component} from '@angular/core';
import {Router} from "@angular/router";
// Services
import {ComponentService} from "@services/components/component.service";
import {BrowserService} from "@services/components/browser.service";
import { TranslateModule } from '@ngx-translate/core';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { NgIf, NgClass, LowerCasePipe } from '@angular/common';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    standalone: true,
    imports: [NgIf, NavigationComponent, PersonalDataComponent, TermsOfUseComponent, NgClass, LowerCasePipe, TranslateModule]
})
export class PrivacyPolicyComponent implements AfterViewInit {
  isActive!: string;
  title = 'processing_policy_data_recovery'
  isMobile!: boolean;

  constructor(
    private componentService: ComponentService,
    private browserService: BrowserService,
    private router: Router
  ) {
    this.isMobile = this.browserService.isMobileDevice;
    this.isActive = this.isMobile ? '' : 'first'
  }

  ngAfterViewInit(): void {
    this.componentService.scrollToTop()
  }

  changeOrderType(data: string): void {
    if (this.isActive == data) {
      return;
    }
    this.componentService.scrollToTop()
    this.title = data == 'first' ? 'processing_policy_data_recovery' : 'public_offer'
    this.isActive = data;
    if (this.isMobile) {
      this.router.navigate(['privacy-policy', this.title])
    }
  }
}
