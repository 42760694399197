import {OrderReviewStatusEnum} from "@interfaces/orders/order.interface";

export enum OrderStatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  COLLECTED = 'COLLECTED',
  ON_THE_WAY = 'ON_THE_WAY',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED'
}


export const OrderStatusTextConst = {
  [OrderStatusEnum.PENDING]: 'orders.PENDING',
  [OrderStatusEnum.ACCEPTED]: 'orders.ACCEPTED',
  [OrderStatusEnum.COLLECTED]: 'orders.COLLECTED',
  [OrderStatusEnum.ON_THE_WAY]: 'orders.ON_THE_WAY'
}

export const OrderStatusConst: {
  [key: string]: OrderStatusValue
} = {
  [OrderStatusEnum.PENDING]: {
    color: '#97979780',
    text:'Заказ создан, ожидаем ответ от магазина',
    priority: 0,
    resource: {
      grey: {svg: "clock-snooze-gray", color: '#979797'},
      red: {svg: "clock-snooze-active", color: '#FF4230'},
      black: {svg: "clock-snooze", color: '#303030'}
    }
  },
  [OrderStatusEnum.ACCEPTED]: {
    color: '#54B644',
    text:'Магазин подтвердил заказ',
    priority: 1,
    resource: {
      grey: {svg: "check-circle-gray", color: '#979797'},
      red: {svg: "check-circle-active", color: '#FF4230'},
      black: {svg: "check-circle", color: '#303030'}
    }
  },
  [OrderStatusEnum.COLLECTED]: {
    color: '#',
    text:'Заказ собран, скоро передадим курьеру',
    priority: 2,
    resource: {
      grey: {svg: "asterisk-gray", color: '#979797'},
      red: {svg: "asterisk-active", color: '#FF4230'},
      black: {svg: "asterisk", color: '#303030'}
    }
  },
  [OrderStatusEnum.ON_THE_WAY]: {
    color: '#',
    text:'Заказ уже в пути!',
    priority: 3,
    resource: {
      grey: {svg: "rocket-gray", color: '#979797'},
      red: {svg: "rocket-active", color: '#FF4230'},
      black: {svg: "rocket", color: '#303030'}
    }
  },
  [OrderStatusEnum.DELIVERED]: {
    color: '#54B644',
  },
  [OrderStatusEnum.CANCELED]: {
    color: '#FF4230',
  },
}

export interface OrderStatusValue {
  color: string,
  text?: string,
  priority?: number,
  resource?: {
    grey: { svg: string, color: string },
    red: { svg: string, color: string },
    black: { svg: string, color: string },
  }
}


export const orderReviewTextConst: {
  [key: string]: orderReviewTextValue
} = {
  [OrderReviewStatusEnum.REJECTED]: {
    color: '#B22113',
    emoji: "👎🏻",
    text: "Вы отклонили видео"
  },
  [OrderReviewStatusEnum.APPROVED]: {
    color: '#60C623',
    emoji: "👍🏻",
    text: "Вы одобрили видео"
  },
  [OrderReviewStatusEnum.TIMEOUT]: {
    color: '#979797',
    emoji: "📹",
    text: "Видео с вашим заказом"
  }
}


export interface orderReviewTextValue {
  color?: string,
  emoji?: string,
  text?: string
}
