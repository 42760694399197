export const formMessages: { [key: string]: { [key: string]: string } } = {
  email: {
    pattern: 'Invalid email format',
    required: 'Email is required'
  },
  password: {
    required: 'Password is required'
  },
  confirmPassword: {
    mustMatch: 'Password confirmation not match'
  },
  // Role Management Module
  description: {
    required: 'Description is required'
  },
  name: {
    required: 'Name is required'
  },
  firstName: {
    required: 'First name is required'
  },
  lastName: {
    required: 'Last name is required'
  },
  phoneNumber: {
    required: 'Phone number is required',
    pattern: 'Phone number format is not valid'
  },
  flowerIds: {
    required: 'Flowers is required',
  },
  deliveryPrice: {
    required: 'Delivery Price is required',
  },
  radius: {
    required: 'Radius is required',
  },
  averageDeliveryTime: {
    required: 'Hour is required',
  }
}
