import {Component} from '@angular/core';
import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {NavigationEnd, Router} from "@angular/router";
//Services
import {AuthService} from "@services/auth/auth.service";
import {ComponentService} from "@services/components/component.service";
//Components
import {BonusInfoDialogComponent} from "../../../shared-modules/global/bonus-info-dialog/bonus-info-dialog.component";
import {SearchDialogComponent} from "../../search-dialog/search-dialog.component";
//Pipes
import {PriceSpacesPipe} from "../../../pipes/string-methods/price-spaces.pipe";

@Component({
  selector: 'app-top-navbar',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    PriceSpacesPipe,
    SearchDialogComponent
  ],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.scss'
})
export class TopNavbarComponent {
  open = false;
  bonusAmount!: number;

  constructor(private authService: AuthService,
              private dialog: MatDialog,
              private router: Router,
              private componentService: ComponentService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url !== '/products') {
          this.openOrCloseSearchComponent(false)
        }else {
          this.componentService.hideOrShowScrollOfMainComponent('unset')
        }
      }
    })
    const userInfo = this.authService.getUserInfo();
    if (!userInfo || !userInfo.bonusAmount) {
      return;
    }
    this.bonusAmount = userInfo.bonusAmount;
  }

  openOrCloseSearchComponent(openOrClose: boolean): void {
    this.open = openOrClose;
    this.componentService.hideOrShowScrollOfMainComponent( this.open ? 'hidden' : 'unset')
  }

  openBonusInfoDialog(): void {
    const dialogRef = this.dialog.open(BonusInfoDialogComponent, {
      width: '512px',
      height: 'auto',
      data: this.authService.getUserInfo()
    });
    dialogRef.afterClosed().subscribe((data?: boolean) => {
      if (!data) {
        return;
      }
      this.authService.checkTokenAndRedirectToAuth();
    })
  }

}
