export interface RequestDataInterface extends RequestDataInterfaceBase {
  feedback?: string
}

export interface RequestDataInterfaceBase {
  service?: number,
  delivery?: number,
  priceAndQuality?: number
}

export interface OrderRateInterface {
  id: number,
  totalAmount: number
}

export enum StarRateEnum {
  PRICE_QUALITY = "PRICE_QUALITY",
  SERVICE = "SERVICE",
  DELIVERY = "DELIVERY"
}

