import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ParseDateService {
  toLocaleTime(time: any, format: string = '', dontChangeOffset = false): string {
    format = (format) ? format : 'MM/DD/yy hh:mm';
    let momentDate = moment(time);
    momentDate.locale('ru');

    if (dontChangeOffset) {
      return momentDate.format(format)
    }

    if (format !== 'UTC') {
      return momentDate.add(-((new Date()).getTimezoneOffset()), 'minutes').format(format);
    } else {
      return momentDate.utc(true).format('MM/DD/yy hh:mm')
    }
  }

  changeFormat(date: string, format: string): string {
    return moment(date).format(format);
  }

  getDiapasonRange(deliverOn: string, diapason: number, format  = 'HH:mm'): string {
    return moment(deliverOn).add(diapason, 'milliseconds').locale('ru').format(format);
  }

}
