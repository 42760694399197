import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RequestMethodsService {
  constructor(private http: HttpClient) {
  }

  get(endpoint: string): Observable<any> {
    return this.http.get(environment.dataEndpoint +'/'+ endpoint);
  }

  post(endpoint: string, data: any, headers: any = {}): Observable<any> {
    return this.http.post(environment.dataEndpoint +'/'+ endpoint, data, {headers});
  }

  put(endpoint: string, data: any): Observable<any> {
    return this.http.put(environment.dataEndpoint +'/'+ endpoint,data)
  }

  delete(endpoint: string): Observable<any> {
    return this.http.delete(environment.dataEndpoint +'/'+ endpoint)
  }

  getAnotherDomain(url: string): Observable<any> {
    return this.http.get(url);
  }
}





