<div class="main" id="main" (scroll)="onScroll($event)" #mainComponent>

  <ng-container  *ngIf="isBrowser && isMobile !== null">
    <ng-container *ngIf="isMobile;else desktop">
      <app-mobile-navbar></app-mobile-navbar>
    </ng-container>
    <ng-template #desktop>
      <app-navbar [scrolled]="scrolled"></app-navbar>
    </ng-template>
  </ng-container>

  <div class="body_container">
    <section class="container">
      <router-outlet></router-outlet>
    </section>
  </div>

  <ng-container *ngIf="isBrowser">
    <app-footer ></app-footer>

    <ng-container *ngIf="isMobile">
      <app-mobile-menu></app-mobile-menu>
    </ng-container>
  </ng-container>

</div>
