<div class="bonus_container">
  <app-clear-button (closeButton)="close()"></app-clear-button>
  <div class="font_15 bonus_text_container text_center">
    <div>
      <img src="assets/images/icons/navbar/bonus_icon.svg">
      <span class="text_title_middle red_color ml_8">{{'bonus_info.title' | translate}}</span>
    </div>
    <div class="first_description margin_auto ">
      <span class="bold">{{'bonus_info.description1' | translate}}</span>
      <span class="bold_700 ml_4">{{'bonus_info.one_bonus' | translate}}</span>
    </div>
    <div class="second_description margin_auto">
      <span class="bold">{{'bonus_info.description2' | translate}}</span>
      <span class="bold_700 ml_4">{{'bonus_info.up_to_99' | translate}}</span>
    </div>
    <div class="third_description margin_auto text_gray_color">
      <span class="bold">{{'bonus_info.must_login' | translate}}</span> <br>
      <span class="bold">{{'bonus_info.for_accumulating' | translate}}</span>
    </div>
  </div>

  <div class="personal_promo flex_column_center_align_center" *ngIf="data && data.promo">
    <div class="text_title_middle title_promo">
      {{'bonus_info.personal_promo' | translate}}
    </div>

    <div class="flex_between_align_center copy w_100 mt_16 br_16">
      <span class="font_15 bold_600">{{data.promo}}</span>
      <img src="assets/images/icons/copy_icon.svg" class="pointer" (click)="copyPromo()">
    </div>

    <div class="info font_15 mt_16 text_center">
      <span class="bold">{{'bonus_info.send_promo' | translate}}</span> <br>
      <span class="bold_700">{{'bonus_info.get_discount' | translate}}</span> <br>
      <span class="bold_700">{{'bonus_info.get_bonus' | translate}}</span>
    </div>
  </div>


  <button class="main_buttons black_btn w_100 big_btn flex_center_align_center"
          (click)=" close(data ? null : true) "
          *ngIf="data && !data.promo || !data">
    {{ data ? ('bonus_info.good_thank_you' | translate) : ('bonus_info.login_or_register' | translate) }}
  </button>

<!--  <button class="main_buttons black_btn w_100 big_btn"-->
<!--          *ngIf="data?.promo">-->
<!--    {{'bonus_info.click_to_send' | translate}}-->
<!--  </button>-->

  <button class="main_buttons black_btn w_100 big_btn flex_center_align_center"
          *ngIf="data?.promo"
          (click)="close()"
  >
    {{ ('bonus_info.continue_shopping' | translate)}}
  </button>


  <div class="font_15 bold flex_center_align_center mt_16 pointer"
       (click)="close()"
       *ngIf="!data">
    {{ !data ? ('bonus_info.continue_in_guest_mode' | translate) : ('bonus_info.continue_shopping' | translate) }}
    <img src="assets/images/icons/rating/arrow_right.svg" class="ml_4">
  </div>
</div>
