import {Component, Input} from '@angular/core';
import {NgClass, NgIf, NgStyle} from "@angular/common";
//Constants
import {AddToFavoriteTypeEnum as FromComponentTypeEnum} from "@interfaces/components/enums/add-to-favorite-status.enum";

//Pipes
import {PriceSpacesPipe} from "../../../pipes/string-methods/price-spaces.pipe";

@Component({
  selector: 'app-bonus-display',
  standalone: true,
  imports: [
    PriceSpacesPipe,
    NgIf,
    NgStyle,
    NgClass
  ],
  templateUrl: './bonus-display.component.html',
  styleUrl: './bonus-display.component.scss'
})
export class BonusDisplayComponent {
  @Input() bonus!: number;
  @Input() type!: FromComponentTypeEnum;
  @Input() discount: boolean = false;
  @Input() nextTo: boolean = false;
  fromComponentTypeEnum = FromComponentTypeEnum;
}
