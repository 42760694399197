<div class="star_container">
  <div class="title text_center bold">
    {{title | translate}}
  </div>
  <div class="star_items">
    <div *ngFor="let star of [1,2,3,4,5]; let i = index">
      <img class="pointer"
           [src]="'assets/images/icons/rating/'+((selectedIndex !== null) && selectedIndex >= i ? 'active_star':'star') + '.svg'"
           (click)="rate(i)"
      >
    </div>
  </div>
</div>
