import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
    selector: '[appHorizontalScrollDrag]',
    standalone: true
})
export class HorizontalScrollDragDirective {
  private isMouseDown = false;
  private startX!: number;
  private scrollLeft!: number;
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.startX = event.pageX - this.elRef.nativeElement.offsetLeft;
    this.scrollLeft = this.elRef.nativeElement.scrollLeft;
    this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'grabbing');
    this.applyStylesToChildNodes('user-select','none')
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
    const x = event.pageX - this.elRef.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 3;
    this.elRef.nativeElement.scrollLeft = this.scrollLeft - walk;
    this.applyStylesToChildNodes('user-select','none')
    this.applyStylesToChildNodes('pointer-events','none')
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.isMouseDown = false;
    this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'grab');
    this.applyStylesToChildNodes('pointer-events','auto')

  }


  private applyStylesToChildNodes(styleName: string, value: string) {
    const children = this.elRef.nativeElement.childNodes;
    for (let i = 0; i < children.length; i++) {
      if (children[i].nodeType === 1) {
        this.renderer.setStyle(children[i], styleName, value);
      }
    }
  }
}
