import {Component, Inject} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
//Services
import {BrowserService} from "@services/components/browser.service";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {ShowMessageService} from "@services/messages/show-message.service";

//Constants
import {
  RequestDataInterface, RequestDataInterfaceBase,
  StarRateEnum,
} from "@interfaces/components/star-rating.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from '../../../partials/shared-modules/global/input/input.component';
import { StarsComponent } from './stars/stars.component';
import { ClearButtonComponent } from '../../../partials/shared-modules/global/clear-button/clear-button.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    standalone: true,
    imports: [NgIf, ClearButtonComponent, StarsComponent, FormsModule, ReactiveFormsModule, InputComponent, NgClass, TranslateModule]
})
export class StarRatingComponent {
  isMobile !: boolean;
  formData = new UntypedFormGroup({
    comment: new UntypedFormControl()
  })
  saveButtonDisableState = true;
  starRateEnum = StarRateEnum;
  //collect data to send
  data: RequestDataInterface = {}

  constructor(public dialogRef: MatDialogRef<StarRatingComponent>,
              private browserService: BrowserService,
              private requestService: RequestMethodsService,
              private showMessageService: ShowMessageService,
              @Inject(MAT_DIALOG_DATA) public orderData: { orderId: number }
  ) {
    this.isMobile = this.browserService.isMobileDevice;
  }

  enableSaveAndGetValue(selectedStarAndEnableSave: number | null, type: StarRateEnum) {
    switch (type) {
      case StarRateEnum.PRICE_QUALITY:
        this.updateDataForRateByType('priceAndQuality', selectedStarAndEnableSave);
        break;
      case StarRateEnum.SERVICE:
        this.updateDataForRateByType('service', selectedStarAndEnableSave);
        break;
      case StarRateEnum.DELIVERY:
        this.updateDataForRateByType('delivery', selectedStarAndEnableSave);
        break;
    }
    this.toggleSaveButtonStateMain();
  }

  updateDataForRateByType(field: string, value: number | null): void {
    const passedField = (field as keyof RequestDataInterfaceBase);
    if (value === null) {
      delete this.data[passedField];
    } else {
      this.data[passedField] = value;
    }
  }

  validateAndCollectData(): boolean {
    const {feedback, priceAndQuality, delivery, service} = this.data;

    if ([priceAndQuality, delivery, service].every(e => !e) && feedback) {
      return true;
    }
    return ![priceAndQuality, delivery, service].some(e => !e);
  }

  toggleSaveButtonStateMain(): void {
    this.saveButtonDisableState = !this.validateAndCollectData();
  }

  handleInputChange(inputValue: string): void {
    this.data.feedback = inputValue
    this.toggleSaveButtonStateMain();
  }

  sendRate(): void {
    if (!this.validateAndCollectData()) {
      return;
    }
    this.requestService.post(`${EndpointConstant.orders}/${EndpointConstant.rate}/${this.orderData.orderId}`, {...this.data})
      .subscribe(() => {
        this.dialogRef.close(true);
        this.showMessageService.showCustomMessage('Ваш отзыв успешно отправлен', 'success')
      })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
