import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-clear-button',
    templateUrl: './clear-button.component.html',
    styleUrls: ['./clear-button.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class ClearButtonComponent {
  @Input() title!: string;
  @Output() closeButton = new EventEmitter<any>();
}
