<div class="login-container br_16 w_100">
  <app-clear-button [title]="'auth.login'" (closeButton)="close()" *ngIf="!isMobile"></app-clear-button>
 <div class="mt_24">
    <app-phone-number *ngIf="!openSetCode"
                      [phoneNumber]="phoneNumber"
                      (sendCodeToPhoneEvent)="sendCodeToPhone($event)">
    </app-phone-number>
 </div>

  <app-set-code *ngIf="openSetCode"
                [phoneCode]="phoneCode"
                [phoneNumber]="phoneNumber"
                (changeNumberEmitter)="backToChangeNumber()"
                (closeLoginDialogAfterSuccess)="close()"
                (sendCodeAgainEmitter)="sendCodeAgain()">
  </app-set-code>
</div>

<div id="recaptcha-container"></div>



