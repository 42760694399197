// Modules
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxOtpInputModule} from 'ngx-otp-input';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {GlobalModule} from "../../partials/shared-modules/global/global.module";
import { RecaptchaV3Module} from "ng-recaptcha";
//components
import {LoginComponent} from './login/login.component';
import {PhoneNumberComponent} from './login/phone-number/phone-number.component';
import {SetCodeComponent} from './login/set-code/set-code.component';
import {ErrorMessageComponent} from './login/set-code/error-message/error-message.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxMaskPipe,
        NgxMaskDirective,
        NgxOtpInputModule,
        GlobalModule,
        RecaptchaV3Module,
        LoginComponent,
        PhoneNumberComponent,
        SetCodeComponent,
        ErrorMessageComponent,
        MobileLoginComponent
    ],
    providers: [
        provideNgxMask(),
    ],
    exports: [
        LoginComponent
    ]
})
export class AuthModule {
}
