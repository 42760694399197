import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf, NgFor, NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
//services
import {BrowserService} from "@services/components/browser.service";
//components
import {MatOption} from '@angular/material/core';
import {FormsModule} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
//constants
import {SimpleSortByInterface, SortByInterface} from "@interfaces/components/sortBy.interface";

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    TranslateModule,
    MatRadioButton,
    MatRadioGroup,
  ],
})
export class SortByComponent {
  @Input() set defaultSorting(defaultSorting: SimpleSortByInterface) {
    this.sortBy = this.sorting.find(e => e.sort == defaultSorting.sort && e.direction == defaultSorting.direction) || this.sorting[0]
  }

  @Input() set sortingForShops(data: boolean) {
    if (!data) {
      return;
    }
    this.sorting[0].sort = 'AVERAGE_RATE';
    this.sorting[0].direction = 'DESC';
  }

  @Output() sortFilterEmitter = new EventEmitter();

  isMobile!: boolean;
  sorting: Array<SortByInterface> = [
    {id: 1, direction: 'ASC', sort: 'PRIORITY', text: 'popularity'},
    {id: 2, direction: 'DESC', sort: 'PRICE', text: 'desc_price'},
    {id: 3, direction: 'ASC', sort: 'PRICE', text: 'asc_price'}
  ];
  sortBy!: SortByInterface;

  constructor(private browserService: BrowserService) {
    this.isMobile = this.browserService.isMobileDevice;
  }

  onSelectChange(): void {
    this.sortFilterEmitter.emit(this.sortBy);
  }
}
