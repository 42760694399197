import {Component, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import {NgIf} from '@angular/common';
//constants
import {BasketInterface} from "@interfaces/components/basket.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {CityOrAddressSelectionInterface} from "@interfaces/catalog/filtration-default-state.const";
//services
import {CityService} from "@services/city/city.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {BasketService} from "@services/basket/basket.service";
import {AuthService} from "@services/auth/auth.service";
import {BrowserService} from "@services/components/browser.service";
//pipes
import {PriceSpacesPipe} from "../../../pipes/string-methods/price-spaces.pipe";

@Component({
  selector: 'app-mobile-catalog-basket-button',
  templateUrl: './mobile-catalog-basket-button.component.html',
  styleUrls: ['./mobile-catalog-basket-button.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule, PriceSpacesPipe]
})
export class MobileCatalogBasketButtonComponent implements OnDestroy {
  subjectForBasketUpdates!: Subscription;
  basket!: BasketInterface;
  cityModel: CityOrAddressSelectionInterface | null = {};
  isMobile = false;

  constructor(private cityService: CityService,
              private browserService: BrowserService,
              private globalSubscriptionService: GlobalSubscriptionService,
              private basketService: BasketService,
              private authService: AuthService,
              private router: Router
  ) {
    this.isMobile = this.browserService.isMobileDevice;
    this.getBasket();
    this.subscribeForBasketUpdates()
  }

  subscribeForBasketUpdates(): void {
    this.subjectForBasketUpdates = this.globalSubscriptionService.getMessage()
      .subscribe((data: { type: string, message: any }) => {
        if ([UpdateSubscriptionTypesEnum.UPDATE_BASKET, UpdateSubscriptionTypesEnum.CITY_SELECTION].includes(data.type as UpdateSubscriptionTypesEnum)
        ) {
          if (data.message) {
            this.cityModel = data.message;
          }
          this.getBasket();
        }
      })
  }

  getBasket(): void {
    if (!this.authService.checkToken()) {return;}
    this.basketService.getBasket().subscribe((res: BasketInterface) => {
      this.basket = res;
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.ADD_BOTTOM_BASKET, !!this.basket?.basketItems?.length);
    });
  }

  navigateToBasket(): void {
    this.router.navigate(['basket'])
  }

  ngOnDestroy(): void {
    this.subjectForBasketUpdates?.unsubscribe();
    this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.ADD_BOTTOM_BASKET, false);
  }
}
