export interface TokenInterface {
  accessToken	:string
  refreshToken	:string
  tokenType	:string
  expiresIn	:string
  userId	:number
  roles:Array<string>
}

export enum CheckToken {
  INVALID,
  VALID,
  PENDING
}
