<div class="download_app_container">
  <div class="mt_18 title_description_container flex_column_center_align_center text_center">
    <h1 class="title text_title_middle bold_800">
      {{'download_app.for_all_devices' | translate}}
    </h1>
    <p class="description text_center font_15">
      {{'download_app.your_personal_market_place' | translate}}
    </p>
  </div>

  <div class="download_icons">
    <div class="application_icon pointer br_16 flex_center_align_center"
         *ngFor="let link of downloadLinksConfig"
         (click)="downloadApplicationLink(link.link)">
      <img [src]="link.image">
    </div>
  </div>

  <div class="back_to_catalog">
    <button class="main_buttons bold_700 text_normal br_16 w_100" (click)="backToCatalog()">
      {{"download_app.back_to_catalog" | translate }}
    </button>
  </div>


  <div class="mobile_phone">
    <img src="assets/images/icons/download_app/phone.png" class="w_100 h_100">
  </div>
</div>
