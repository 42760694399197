export const FiltrationDefaultStateConst = {
  filter: {
    cityId: 0,
    latitude: 0,
    longitude: 0
  },
  page: 1,
  size: 32,
  sorting: {
    sort: 'PRIORITY',
    direction: 'ASC'
  }
}

export const FiltrationDefaultStateForShopsConst = {
  filter: {
    cityId: 0,
    latitude: 0,
    longitude: 0
  },
  page: 1,
  size: 30,
  sorting: {
    sort: 'SHOP_NAME',
    direction: 'ASC'
  }
}


export interface CityOrAddressSelectionInterface {
  cityId?: number,
  latitude?: number,
  longitude?: number
}
