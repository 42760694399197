import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priceSpaces',
  standalone: true
})
export class PriceSpacesPipe implements PipeTransform {

  transform(value: number | string): string {
    if (typeof value === 'number') {
      value = value.toString();
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
