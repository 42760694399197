import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchInArray',
    pure: false,
    standalone: true
})
export class SearchInArrayPipe implements PipeTransform {
  transform(
    arr: Array<number> | undefined,
    id: number
  ): boolean {
    if (arr) {
      return !!arr.find(e => e == id)
    } else {
      return false
    }
  }

}
