import {Injectable} from '@angular/core';
import {Swiper, Autoplay} from "swiper";
//Constants
import {SwiperConfigsInterface} from "@interfaces/components/swiper-configs.interface";
import {SwiperTypeEnum} from "@interfaces/components/enums/swiper-type.enum";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
// @ts-ignore
Swiper.use(Autoplay);

@Injectable({
  providedIn: 'root'
})
export class SwiperService {
  swipers: { [key: string]: Swiper } = {};

  constructor(private globalSubscriptionService: GlobalSubscriptionService) {
  }

  makeHorizontalSwipe(id?: string, isSingle = false, config?: SwiperConfigsInterface): void {
    const swiper = new Swiper((config?.swiperClass ? config.swiperClass : `.swiper-container-horizontal-${id}`), {
      pagination: {
        el: (config?.paginationClass ? config.paginationClass : `.swiper-pagination-${id}`),
        clickable: true,
      },
      allowTouchMove: !config?.allowTouchMove,
      direction: 'horizontal',
      slidesPerView: "auto",
      spaceBetween: 2,
      loop: config?.loop || false,
      speed: 400,
      autoplay: config?.autoPlay || false
    });

    if (!id) {
      return;
    }
    this.swipers[id] = swiper;
    // this.globalSubscriptionService.notifyIndexChange(this.swipers[id].activeIndex)
    if (this.swipers[id] && id === SwiperTypeEnum.SINGLE_PAGE) {
      //   this.swipers[id].on('slideChange', (event) =>  this.globalSubscriptionService.notifyIndexChange(event.activeIndex));
      this.swipers[id].allowTouchMove = false
    }
    //
    // if (isSingle) {
    //   const prevButton = document.getElementById(`custom-prev-button-horizontal`);
    //   const nextButton = document.getElementById(`custom-next-button-horizontal`);
    //   if (prevButton) {
    //     prevButton.addEventListener('click', (e) => {
    //       e.stopPropagation();
    //       this.swipers[id].slidePrev();
    //     });
    //   }
    //   if (nextButton) {
    //     nextButton.addEventListener('click', (e) => {
    //       e.stopPropagation();
    //       this.swipers[id].slideNext();
    //     });
    //   }
    // }
  }

  openImgInBiggerView(activeIndex: any, type: SwiperTypeEnum, speed = 400): void {
    this.swipers[type].slideTo(activeIndex, speed);
  }
}
