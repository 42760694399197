<div class="add_or_delete_container flex_align_center">
  <div class="reduce_and_add_container flex_align_center br_32">
    <div class="reduce_and_add_count_button pointer flex_center_align_center" (click)="reduceCount()">
      <img src="assets/images/icons/ik_dekrement.svg">
    </div>
    <div class="count font_15 bold_800">
      {{productCard.countInBasket}}
    </div>
    <div class="reduce_and_add_count_button  flex_center_align_center pointer" (click)="addCount()">
      <img src="assets/images/icons/ik_add.svg">
    </div>
  </div>
  <div class="delete_button br_32 flex_center_align_center ml_12 pointer"
       *ngIf="type!==addAndReduceStatusEnum.SINGLE_PAGE"
       (click)="deleteFromBasket()">
    <img src="assets/images/icons/trash.svg">
  </div>
</div>
