import {Component, EventEmitter, input, Input, Output} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import CyrillicToTranslit from "cyrillic-to-translit-js";

//Directives
import {InfiniteScrollDirective} from "ngx-infinite-scroll";

//Constants
import {
  ProductListInterface,
  ProductPaginationInterface,
  ProductsFiltrationInterface
} from "@interfaces/catalog/products.interface";
import {FiltrationDefaultStateConst} from "@interfaces/catalog/filtration-default-state.const";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {AddToFavoriteTypeEnum as FromComponentTypeEnum} from "@interfaces/components/enums/add-to-favorite-status.enum";

//Services
import {CityService} from "@services/city/city.service";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {BrowserService} from "@services/components/browser.service";

//Components
import {LoadingComponent} from "../../shared-modules/global/loading/loading.component";
import {EmptyPageComponent} from "../../shared-modules/global/empty-page/empty-page.component";
import {BonusDisplayComponent} from "../../shared-modules/product/bonus-display/bonus-display.component";

//Pipes
import {PriceSpacesPipe} from "../../pipes/string-methods/price-spaces.pipe";
import {SearchInStringPipe} from "../../pipes/string-methods/search-in-string.pipe";
import {MillisecondsToMinutesPipe} from "../../pipes/date-methodes/milliseconds-to-minutes.pipe";

@Component({
  selector: 'app-search-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    NgForOf,
    NgClass,
    NgIf,
    SearchInStringPipe,
    InfiniteScrollDirective,
    LoadingComponent,
    EmptyPageComponent,
    BonusDisplayComponent,
    PriceSpacesPipe,
    MillisecondsToMinutesPipe
  ],
  templateUrl: './search-dialog.component.html',
  styleUrl: './search-dialog.component.scss'
})
export class SearchDialogComponent {
  @Input() set searchBy(data: string) {
    this.searchFor(data);
  };

  @Output() closeSearchComponentEmitter = new EventEmitter<void>();

  isMobile!: boolean;
  searchTimeout!: ReturnType<typeof setTimeout> | null;
  filterData: ProductsFiltrationInterface = FiltrationDefaultStateConst;
  searchedProducts !: ProductPaginationInterface;
  loading !: boolean;
  fromComponentTypeEnum = FromComponentTypeEnum.BASKET;

  constructor(private cityService: CityService,
              private requestService: RequestMethodsService,
              private browserService: BrowserService
  ) {
    this.isMobile = this.browserService.isMobileDevice;
    this.loading = !this.isMobile;
    this.filterData = this.cityService.deleteCityOrAddressFromFilterData(this.filterData);
  }

  getSearchedValues(force = false, changePage = false): void {
    this.loading = true;
    if (force) {
      this.filterData.page = 1
    }
    const query = `?page=${this.filterData.page}&size=${this.filterData.size}`
    this.requestService.post(`${EndpointConstant.products}/${EndpointConstant.filter}${query}`,
      this.filterData
    ).subscribe((response: ProductPaginationInterface) => {
      this.searchedProducts = {
        ...response,
        content: changePage ? [...this.searchedProducts.content, ...response.content] : response.content
      }
      this.loading = false
    }, () => this.loading = false)
  }

  changePage(): void {
    if (this.filterData.page >= this.searchedProducts.totalPages) {
      return;
    }
    this.filterData.page++;
    this.getSearchedValues(false, true);
  }

  onScroll(event: any) {
    if (Math.round(event.target.offsetHeight + event.target.scrollTop) >= event.target.scrollHeight - 1) {
      this.changePage();
    }
  }

  redirectToProductSinglePage(item: ProductListInterface) {
    const name = CyrillicToTranslit().transform(item.title.replace(
      /[&\/\\#,+()$~%.'":*?<>{}]/g,''), "-"
    ).toLowerCase();
    window.location.href = `/product/${name}/${item.id}`;
  }

  searchFor(value: string): void {
    this.filterData.searchBy = value;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }

    this.searchTimeout = setTimeout(() => {
      this.getSearchedValues(true);
    }, 300)
  }

  backButtonClick(): void {
    this.searchBy = '';
    this.closeSearchComponentEmitter.emit()
  }
}
