import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Location, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
//Constants
import {NavigationBackButtonTypeEnum} from "@interfaces/components/enums/navigation-back-button-type.enum";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {CityInterface} from "@interfaces/components/cities.interface";
//Services
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";

@Component({
  selector: 'app-bottom-navbar',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf
  ],
  templateUrl: './bottom-navbar.component.html',
  styleUrl: './bottom-navbar.component.scss'
})
export class BottomNavbarComponent {
  @Input() set selectedData(data: { address: FavoriteAddressInterface } | { city: CityInterface }) {
    if (!data) {
      return;
    }
    this.selectedAddressName = 'address' in data ? data.address.fullAddress : data.city.name
  };

  @Input() navigationIcon!: NavigationBackButtonTypeEnum;

  @Output() selectCityEvent = new EventEmitter<void>();
  @Output() selectAddressToDeliverEvent = new EventEmitter<boolean>();
  navigationIconTypeEnums = NavigationBackButtonTypeEnum;
  selectedAddressName!: string;

  constructor(private router: Router,
              private globalSubscriptionService: GlobalSubscriptionService,
              private location: Location
  ) {
  }

  backToPreviousRoute(): void {
    console.log(this.navigationIcon)
    if (this.navigationIcon === NavigationBackButtonTypeEnum.ORDER_CREATION) {
      this.globalSubscriptionService.sendMessage(
        UpdateSubscriptionTypesEnum.NAVIGATE_BACK_BUTTON_EMIT)
    } else if (this.navigationIcon === NavigationBackButtonTypeEnum.CATALOG) {
      return;
    } else if (this.navigationIcon === NavigationBackButtonTypeEnum.MOBILE_MAP && !this.selectedAddressName) {
      this.location.back();
      setTimeout(() => {
        this.selectAddressToDeliverEvent.emit()
      }, 200)
    } else {
      this.location.back();
    }
  }
}
