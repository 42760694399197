import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {GlobalModule} from "../global/global.module";
import {PipesModule} from "../../pipes/pipes.module";

//components
import {CardComponent} from './product-card/product-card.component';
import {SortByComponent} from './section-catalog/filtration/sort-by/sort-by.component';
import {SinglePageComponent} from "./single-page/single-page.component";
import {ImagesDialogComponent} from './single-page/images-dialog/images-dialog.component';
import {VerticalSwiperComponent} from "./single-page/vertical-swiper/vertical-swiper.component";
import {PaginationComponent} from "./section-catalog/pagination/pagination.component";
import {BasketComponent} from "./basket/basket.component";
import {AddAndReduceComponent} from './add-and-reduce/add-and-reduce.component';
import {ProductGalleryComponent} from "./product-gallery/product-gallery.component";
import {MobileBasketComponent} from './mobile-basket/mobile-basket.component';
import {
  MobileCatalogBasketButtonComponent
} from './mobile-catalog-basket-button/mobile-catalog-basket-button.component';

//material components
import {MatSliderModule} from "@angular/material/slider";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {Pagination, Swiper} from "swiper";
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//directives
import {HorizontalScrollDragDirective} from "../../directives/horizontal-scroll-drag.directive";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        MatSliderModule,
        MatChipsModule,
        MatIconModule,
        MatSelectModule,
        GlobalModule,
        PipesModule,
        MatBottomSheetModule,
        CardComponent,
        SortByComponent,
        SinglePageComponent,
        ProductGalleryComponent,
        VerticalSwiperComponent,
        ImagesDialogComponent,
        PaginationComponent,
        BasketComponent,
        AddAndReduceComponent,
        MobileBasketComponent,
        MobileCatalogBasketButtonComponent,
        HorizontalScrollDragDirective
    ],
    exports: [
        BasketComponent,
        SinglePageComponent,
        AddAndReduceComponent,
        MobileCatalogBasketButtonComponent
    ]
})
export class ProductModule {
  constructor() {
    Swiper.use([Pagination])
  }
}
