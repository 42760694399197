<div class="search_dialog_container" [class.br_32]="!isMobile">
  <ng-container *ngIf="isMobile">
    <div class="back_button br_50 flex_center_align_center pointer"
         (click)="backButtonClick()"
    >
      <img src="assets/images/mobile_icons/navigate-back_icon.svg">
    </div>
    <div class="search app_input w_100 br_12">
      <img src="assets/images/icons/navbar/search.svg" class="search_icon">
      <input type="text"
             class="position_relative"
             #searchInput
             [value]="filterData.searchBy || ''"
             placeholder="{{'input_placeholder.find_favorite_flowers' | translate}}"
             (keyup)="searchFor(searchInput.value)"
      >
    </div>
  </ng-container>


  <ng-container *ngIf="!loading && searchedProducts?.content?.length && filterData.searchBy">
    <div class="description text_gray_color font_15 bold" *ngIf="!isMobile">
      {{'navbar.search_products' | translate}}
    </div>
    <div class="products_container" (scroll)="onScroll($event)">
      <div *ngFor="let item of searchedProducts?.content" class="products br_8 pointer"
           (click)="redirectToProductSinglePage(item)">
        <div class="image_container br_16">
          <img [src]="item.resourceUrls[0].resourceUrl" class="w_100 h_100">
        </div>
        <div class="title_price_container" [class.flex_column_between]="isMobile">
          <div class="title_and_bonus flex_column">
            <div class="title" [innerHTML]="filterData.searchBy | searchInString : item.title"></div>
            <div class="flex_align_center">
              <span class="bold_800 text_normal_sub price">{{item.price | priceSpaces}}  ₽</span>
              <app-bonus-display *ngIf="item.bonusAmount"
                                 [type]="fromComponentTypeEnum"
                                 [nextTo]="true"
                                 [bonus]="item.bonusAmount"
              ></app-bonus-display>
            </div>
          </div>
          <div class="flex delivery_and_shop_container" *ngIf="isMobile">
            <div class="bold delivery flex_center_align_center mr_8"
                 *ngIf="(item.shop.averageDeliveryTime | millisecondsToMinutes : item.shop.interval) as delivery">
              ~ {{delivery | priceSpaces}} мин.
            </div>
            <div class="flex">
              <div class="image_shop_logo br_50 flow_hide">
                <img [src]="item.shop.imageUrl || 'assets/images/icons/shops/shop-default.svg'" class="w_100 h_100">
              </div>
              <span>{{item.shop.shopName}}</span>
            </div>
          </div>
        </div>
        <div class="arrow_right" *ngIf="isMobile">
          <img src="assets/images/mobile_icons/arrow_left.svg">
        </div>
      </div>
    </div>
  </ng-container>
  <app-loading *ngIf="isMobile" [loading]="loading || false"></app-loading>
  <app-empty-page *ngIf="!loading && !searchedProducts?.content?.length && filterData.searchBy" [settings]="{
                  title:'nothing_found',
                  description:'search_description'
                  }"
  ></app-empty-page>
</div>

<app-loading *ngIf="!isMobile" [loading]="loading || false"></app-loading>

