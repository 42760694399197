import {afterNextRender, Component, ElementRef, ViewChild} from '@angular/core';
import {NgFor, NgIf, NgStyle} from "@angular/common";
import {BrowserService} from "@services/components/browser.service";

@Component({
  selector: 'app-custom-slider',
  standalone: true,
  imports: [NgStyle, NgFor, NgIf],
  templateUrl: './custom-slider.component.html',
  styleUrl: './custom-slider.component.scss'
})
export class CustomSliderComponent {
  @ViewChild('slider') slider!: ElementRef;
  showLeftArrow = false;
  showRightArrow = true;
  isDragging = false;
  startX = 0;
  scrollLeft = 0;

  ngAfterViewInit(): void {
    this.updateArrows();
    const sliderElement = this.slider.nativeElement;

    sliderElement.addEventListener('scroll', () => this.updateArrows());
    sliderElement.addEventListener('mousedown', (e: MouseEvent) => this.onMouseDown(e));
    sliderElement.addEventListener('mouseleave', () => this.onMouseLeave());
    sliderElement.addEventListener('mouseup', () => this.onMouseUp());
    sliderElement.addEventListener('mousemove', (e: MouseEvent) => this.onMouseMove(e));
  }

  scrollLeftFn() {
    this.slider.nativeElement.scrollLeft -= 260;
  }

  scrollRightFn() {
    this.slider.nativeElement.scrollLeft += 260;
  }

  updateArrows() {
    const {scrollLeft, scrollWidth, clientWidth} = this.slider.nativeElement;
    this.showLeftArrow = scrollLeft > 0;
    this.showRightArrow = scrollLeft + clientWidth < scrollWidth;
  }

  onMouseDown(e: MouseEvent) {
    this.isDragging = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
    this.slider.nativeElement.classList.add('active');
  }

  onMouseLeave() {
    this.isDragging = false;
    this.slider.nativeElement.classList.remove('active');
  }

  onMouseUp() {
    this.isDragging = false;
    this.slider.nativeElement.classList.remove('active');
  }

  onMouseMove(e: MouseEvent) {
    if (!this.isDragging) return;
    e.preventDefault();
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // scroll-fastness multiplier
    this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }
}
