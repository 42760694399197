import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
//services
import {RequestMethodsService} from "@services/request/request-methods.service";
//constants
import {EndpointConstant} from "@interfaces/global/endpoint.constant";

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  constructor(private requestService: RequestMethodsService) {
  }

  addProductToFavorites(productId: number): Observable<any> {
    return this.requestService.post(`${EndpointConstant.products}/${productId}/${EndpointConstant.favorites}`,{})
  }

  deleteProductFromFavorites(productId: number): Observable<any> {
    return this.requestService.delete(`${EndpointConstant.products}/${productId}/${EndpointConstant.favorites}`)
  }


  addShopToFavorites(shopId: number): Observable<any> {
    return this.requestService.post(`${EndpointConstant.user}/${EndpointConstant.shop}/${shopId}/${EndpointConstant.favorites}`,{})
  }

  deleteShopFromFavorites(shopId: number): Observable<any> {
    return this.requestService.delete(`${EndpointConstant.user}/${EndpointConstant.shop}/${shopId}/${EndpointConstant.favorites}`)
  }

}
