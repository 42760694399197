import {Component, EventEmitter, Input, Output} from '@angular/core';
//constants
import {AddToFavoriteTypeEnum} from "@interfaces/components/enums/add-to-favorite-status.enum";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
// Services
import {FavoritesService} from "@services/favorites/favorites.service";
import {AuthService} from "@services/auth/auth.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-add-to-favorites-button',
  templateUrl: './add-to-favorites-button.component.html',
  styleUrls: ['./add-to-favorites-button.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgClass]
})
export class AddToFavoritesButtonComponent {
  @Input() classTransparent = false;
  @Input() id!: number;
  @Input() type!: AddToFavoriteTypeEnum;
  @Input() liked!: boolean | undefined;
  @Output() favoriteChangeEventEmitter = new EventEmitter<boolean>();
  submit = false;

  constructor(
    private authService: AuthService,
    private favoritesService: FavoritesService,
    private globalSubscriptionService: GlobalSubscriptionService
  ) {
  }


  addToFavorites(event: Event): void {
    event.stopPropagation();

    if (this.submit || !this.authService.checkTokenAndRedirectToAuth()) {
      return;
    }

    this.submit = true;
    let subscription;
    switch (this.type) {
      case AddToFavoriteTypeEnum.SHOP:
        subscription = this.liked
          ? this.favoritesService.deleteShopFromFavorites(this.id)
          : this.favoritesService.addShopToFavorites(this.id);
        break;
      case AddToFavoriteTypeEnum.PRODUCT:
        subscription = this.liked
          ? this.favoritesService.deleteProductFromFavorites(this.id)
          : this.favoritesService.addProductToFavorites(this.id);
        break;
    }
    subscription?.subscribe(() => {
      this.favoriteChangeEventEmitter.emit(!this.liked);
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_FAVORITES);
      this.submit = false;
    }, () => this.submit = false)
  }
}
