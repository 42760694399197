<div class="bottom_sheet_container">

  <div class="bottom_sheet_container--content">
    <div class="title text_title_middle">
      {{data.title | translate}}
    </div>
    <div class="scroll mt_8" [ngClass]="data.className">
      <ng-container #component></ng-container>
    </div>
  </div>

  <div class="division"></div>
  <div class="save_button_container">
    <button (click)="save()"
            class="save_button_container--btn bold pointer text_normal_sub">
      {{'my_account.apply' | translate}}
    </button>
  </div>
</div>
