<div class="set_code br_16 w_100">
  <div class="title">
    <div class="middle_small_text pb_40">
      <div class="enter_phone_number">{{'auth.we_send_code' | translate}}:</div>
      <div class="flex_between_align_center">
        <span class="text_normal_sub bold_700">{{'+7 ' + (phoneNumber | mask: '(000)-000-00-00')}}</span>
        <span class="change_number pointer" (click)="changeNumber()">
          {{'auth.change_number' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="mt_35 flex_column">
    <div class="input_code flex_between_align_center">
      <ngx-otp-input #otpInput [config]="otpInputConfig"
                     [status]="userInputCode"
                     (fill)="login($event)"
                     (otpChange)="inputCode()">
      </ngx-otp-input>
    </div>
  </div>

<!--  <div class="send_code_again_container mt_15">-->
<!--    <span class="send_code_again_able"-->
<!--          [ngClass]="{'send_code_again_disable' : second}"-->
<!--          (click)="sendCodeAgain()">-->
<!--      {{'auth.send_code_again' | translate}}-->
<!--    </span>-->

<!--    <span *ngIf="second && timeToResend">-->
<!--      <span class="send_code_again_disable">:</span>-->
<!--      <span class="ml_2">00:{{timeToResend}}</span>-->
<!--    </span>-->
<!--  </div>-->

  <app-error-message *ngIf="userInputCode == 'error'"></app-error-message>
</div>
