import {afterNextRender, Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private _isMobileDevice = false;
  private _isTabletDevice = false;

  constructor(private router: Router) {
    afterNextRender(() => {
      this._isMobileDevice = (window.innerWidth < 768);
      this._isTabletDevice = (window.innerWidth < 1023 && window.innerWidth > 768);
    })
  }

  get isMobileDevice(): boolean {
    return this._isMobileDevice;
  }
  get isTabletDevice(): boolean {
    return this._isTabletDevice;
  }

  getIsDesktop(): boolean {
    if (!this._isMobileDevice) {
      // this.router.navigate(['/']);
    }
    return this._isMobileDevice;
  }

}
