import {afterNextRender, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CheckToken} from "@interfaces/auth/login.interface";
import {Router, RouterLink, RouterLinkActive, NavigationEnd} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from "@angular/forms";
import {NgIf, NgClass, NgForOf} from '@angular/common';
import {MatDialog} from "@angular/material/dialog";

// services
import {AuthService} from "@services/auth/auth.service";
import {CityService} from "@services/city/city.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";

// constants
import {BasketInterface} from "@interfaces/components/basket.interface";
import {BasketTypeEnum} from "@interfaces/orders/enums/basket.enum";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";

//components
import {BasketComponent} from '../../../shared-modules/product/basket/basket.component';
import {SearchDialogComponent} from "../../search-dialog/search-dialog.component";
import {BonusInfoDialogComponent} from "../../../shared-modules/global/bonus-info-dialog/bonus-info-dialog.component";

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
  standalone: true,
  providers: [AuthService],
  imports: [RouterLink, RouterLinkActive, NgIf, NgClass, BasketComponent, TranslateModule, NgForOf, FormsModule, SearchDialogComponent]
})
export class BottomNavbarComponent implements OnInit {
  @ViewChild('searchMenu') searchMenu!: ElementRef;
  open = false;
  setTimeOut!: any;
  basketData!: BasketInterface;
  isUser!: CheckToken;
  basketTypeEnum = BasketTypeEnum;
  pages = [
    {
      isActive: false,
      route: '/favorites',
      icon: 'favorites',
      title: 'navbar.favorites'
    },
    {
      isActive: false,
      route: '/orders',
      icon: 'orders',
      title: 'navbar.orders'
    },
    {
      isActive: false,
      route: '/my-account',
      icon: 'profile',
      title: 'my_account.profile'
    }
  ]
  searchBy = '';
  bonusAmount!: number;

  constructor(
    private authService: AuthService,
    private globalSubscriptionService: GlobalSubscriptionService,
    private cityService: CityService,
    private router: Router,
    private dialog: MatDialog
  ) {
    afterNextRender(() => {
      this.isUser = this.authService.checkToken();
      const userInfo = this.authService.getUserInfo();
      if (!userInfo || !userInfo.bonusAmount) {
        return;
      }
      this.bonusAmount = userInfo.bonusAmount;
    })
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        this.pages.forEach((page => page.isActive = (url === page.route)))
      }
    });
  }

  navigateToCatalog(): void {
    window.location.href = '/'
  }

  openSignInDialog(): void {
    if (this.authService.checkTokenAndRedirectToAuth()) {
      return;
    }
  }

  openBonusInfoDialog(): void {
    const dialogRef = this.dialog.open(BonusInfoDialogComponent, {
      width: '512px',
      height: 'auto',
      data: this.authService.getUserInfo()
    });
    dialogRef.afterClosed().subscribe((data?: boolean) => {
      if (!data) { return;}
      this.authService.checkTokenAndRedirectToAuth();
    })
  }

  navigateToMyAccount(): void {
    window.location.href = '/my-account';
  }

  onMouseEnter(): void {
    if (this.setTimeOut) {
      clearTimeout(this.setTimeOut)
    }
    this.open = true
  }

  onMouseOut(): void {
    this.setTimeOut = setTimeout(() => {
      this.open = false;
    }, 300)
  }

  navigateCatalog(): void {
    if (this.router.url === '/') {
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.BACK_TO_CATALOG);
    } else {
      window.location.href = '/products';
    }
  }

  navigateTo(url: string): void {
    if (this.isUser || url === '/') {
      this.router.navigate([url]);
    } else {
      this.openSignInDialog();
    }
  }

  searchFor(value: string): void {
    this.searchBy = value;
  }
}

