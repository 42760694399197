import {afterNextRender, Component, ElementRef, Inject, Optional, PLATFORM_ID, ViewChild} from '@angular/core';
//services
import {BrowserService} from "@services/components/browser.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('mainComponent') mainComponent!: ElementRef;
  isMobile: boolean | null = null;
  scrolled = false;
  isBrowser = false;

  constructor(
    private browserService: BrowserService,
    @Optional() @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    afterNextRender(() => {
      this.isMobile = this.browserService.isMobileDevice;
    })
  }

  onScroll(event: any): void {
    const scrollTop = event.target.scrollTop;
    this.scrolled = scrollTop >= 48;
  }

}
