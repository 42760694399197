import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Observable} from "rxjs";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-standard-bottom-sheet',
    templateUrl: './standard-bottom-sheet.component.html',
    styleUrls: ['./standard-bottom-sheet.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule]
})
export class StandardBottomSheetComponent implements AfterViewInit {
  @ViewChild('component', {read: ViewContainerRef}) componentContainer: ViewContainerRef | undefined;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private bottomSheetRef: MatBottomSheetRef<StandardBottomSheetComponent>,
              private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  component!: ComponentRef<any> | undefined;

  ngAfterViewInit(): void {
    if (!this.data.component) {
      return;
    }
    this.component = this.componentContainer?.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(this.data.component)
    )
    if (this.component) {
      this.component.instance.data = this.data.info;
      this.component.instance.closeBottomSheet?.subscribe((data: any) => {
        this.bottomSheetRef.dismiss(data);
      })
    }

  }

  save(): void {
    if (!this.component) { return; }
    const isObservableMethod = this.component.instance.getDataObservable;
    if (isObservableMethod) {
      const subscriptionToObservable = isObservableMethod.apply(this.component.instance);
      if (subscriptionToObservable.validationError) { return; }
      (subscriptionToObservable as Observable<any>).subscribe((data) => {
        this.bottomSheetRef.dismiss(data || 1);
      })
    } else {
      this.bottomSheetRef.dismiss(this.component.instance.getData ? this.component.instance.getData() : 1);
    }
  }

  close(): void {
    this.bottomSheetRef.dismiss(0);
  }

}
