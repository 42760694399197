import {Component, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
//services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {BrowserService} from "@services/components/browser.service";
//constants
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {PhoneNumberComponent} from "./phone-number/phone-number.component";
import { SetCodeComponent } from './set-code/set-code.component';
import { ClearButtonComponent } from '../../../partials/shared-modules/global/clear-button/clear-button.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ClearButtonComponent,
        PhoneNumberComponent,
        SetCodeComponent,
    ],
})
export class LoginComponent {
  @ViewChild(PhoneNumberComponent) phoneNumberComponent!: PhoneNumberComponent;
  openSetCode = false;
  phoneNumber!: string;
  phoneCode = !environment.production ? '+374' : '+7'
  isMobile!: boolean;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private requestService: RequestMethodsService,
    private browserService: BrowserService
  ) {
    this.isMobile = this.browserService.isMobileDevice;
  }

  sendCodeToPhone(event: { phone: string, disable: boolean, recaptchaToken: string} ): void {
    if (!event?.phone || !event.recaptchaToken) {
      return;
    }

    this.requestService.post(`${EndpointConstant.auth}/${EndpointConstant.send}`, {
      phoneNumber: this.phoneCode + event.phone
    },{
      'g-recaptcha-response': event.recaptchaToken
    }).subscribe(() => {
        this.openSetCode = true;
        this.phoneNumber = event.phone;
        event.disable = false;
        this.phoneNumberComponent.submit = false;
      },() => {
      this.phoneNumberComponent.submit = false;
      event.disable = false;
    })
  }

  backToChangeNumber(): void {
    this.openSetCode = false
  }

  sendCodeAgain(): void {
    // this.sendCodeToPhone({phone: this.phoneNumber, disable: false})
  }

  close(): void {
    if (!this.isMobile) {
      this.dialogRef.close();
    }
  }

}
