export const EndpointConstant = {
  products: 'products',
  filter: 'filter',
  section: 'section',
  all: 'all',
  flowers: 'flowers',
  colors: 'colors',
  cities: 'cities',
  orders: 'orders',
  refreshToken: 'auth/renew',
  favorites: 'favorites',
  favorite: 'favorite',
  basket: 'basket',
  auth: 'auth',
  send: 'send',
  city: 'city',
  users: 'users',
  self: 'self',
  check: 'check',
  cards: 'cards',
  addresses: 'addresses',
  promoCode: 'promocode',
  shop: 'shop',
  user: 'user',
  checkAvailability: 'check-availability',
  rate: 'rate',
  skip: 'skip',
  skipSingle: 'skip-single',
  review: 'review',
  popular: 'popular',
  other: 'other',
  deliveryPrice: 'delivery-price',
}

