<div class="button_container w_100 flex_center_align_center"
     *ngIf="basket && basket.basketItems.length && isMobile"
     (click)="navigateToBasket()">
  <div class="button_block flex_between_align_center br_12 text_normal bold_700 pointer">
    <div class="flex_between_align_center font_15 bold_700">
      <span>{{'navbar.basket.title' | translate}}&nbsp;-&nbsp;</span>
      <span class="total_products">
        {{basket.basketItems.length}} товара
      </span>
    </div>
    <div class="total_price bold_800">
      <div class="font_13">
        {{(basket.totalPrice === basket.deliveryPrice ? '' : basket.totalPrice) | priceSpaces }} ₽
      </div>
      <div class="bonus_amount flex_end_align_center">
        +{{basket.totalBonusAmount}}
        <img src="assets/images/icons/navbar/bonus_icon.svg" class="ml_2">
      </div>
    </div>

  </div>
</div>
