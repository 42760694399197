<div class="header flex_column" [ngClass]="{'slide_up':scrolled}"
>
  <app-banner></app-banner>
  <app-top-navbar
    [selectedData]="cityControl"
    [defaultAddressIsSelected]="defaultAddressIsSelected"
    (selectAddressToDeliverEvent)="openDialogOrBottomSheetAddressSelect($event)"
  ></app-top-navbar>
  <app-bottom-navbar></app-bottom-navbar>
</div>
