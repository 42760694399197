import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {Clipboard} from "@angular/cdk/clipboard";
//Components
import {ClearButtonComponent} from "../clear-button/clear-button.component";
//Constants
import {UsersInterface} from "@interfaces/components/users.interface";
//Services
import {ShowMessageService} from "@services/messages/show-message.service";

@Component({
  selector: 'app-bonus-info-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    ClearButtonComponent,
    NgIf
  ],
  templateUrl: './bonus-info-dialog.component.html',
  styleUrl: './bonus-info-dialog.component.scss'
})
export class BonusInfoDialogComponent {

  constructor(public dialogRef: MatDialogRef<BonusInfoDialogComponent>,
              private clipboard: Clipboard,
              private showMessageService: ShowMessageService,
              @Inject(MAT_DIALOG_DATA) public data: UsersInterface
  ) {
  }

  copyPromo(): void {
    this.clipboard.copy(`${this.data?.promo}`);
    this.showMessageService.showMessageByVariable('copy_promo', 'success');
  }

  close(value: boolean | null = null): void {
    this.dialogRef.close(value)
  }
}
