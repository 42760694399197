<div class="notification_container flex_column_center_between text_center">
  <div class="notification_dialog ">
    <span class="text_title bold_700 ">{{data.title | translate}}</span>
    <div *ngIf="data.content" class="content text_normal_sub bold  mt_15">
      {{data.content | translate}}
    </div>
  </div>
  <div class="buttons flex_center_align_center mt_40">
    <button class="pointer text_normal_sub bold" (click)="close(confirmationActionEnum.APPROVE)">{{data.firstButton | translate}}</button>
    <button class="cancel pointer text_normal_sub bold" *ngIf="data.secondButton"
            (click)="close(confirmationActionEnum.DECLINE)"
    >
      {{data.secondButton | translate}}
    </button>
  </div>
</div>
