import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToHours',
    standalone: true
})
export class SecondsToHoursPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) { return ''; }
    let hours = String(Math.floor(value / 3600));
    let minutes = String(Math.floor(value % 3600));
    if (hours.length === 1) {
      hours = '0' + hours;
    }

    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }

    return hours + ':' + minutes;
  }

}
