import { Pipe, PipeTransform } from '@angular/core';
import {ParseDateService} from "@services/date/parse-date.service";

@Pipe({
    name: 'addDiapasonToDatePipe',
    standalone: true
})
export class AddDiapasonToDatePipe implements PipeTransform {

  constructor(private parseDateService: ParseDateService
  ) {}
  transform(
    date: string,
    diapason: number,
    format?: string
  ): string {
    return this.parseDateService.getDiapasonRange(date, diapason,format);
  }
}
