<div class="vertical_slider_container">
  <div class="swiper-container position_relative w_90 flow_hide">
    <div class="swiper-wrapper" *ngIf="data.length">
      <ng-container *ngFor="let url of data;let i = index">
        <div class="swiper-slide image_container flex_center_align_center flow_hide mt_16 pointer br_20 ml_2"
             [ngClass]="{
             'active_border': url.id === activeSlide?.id,
             'mr_20': !pending
             }"
             (click)="clickToImage(i)"
             (mouseenter)="onImageHover('enter',i)"
             (mouseleave)="onImageHover('leave')"
        >
          <img [src]="url.resourceUrl"></div>
      </ng-container>
    </div>
    <div id="custom-prev-button-vertical">
      <img src="assets/images/icons/top_.svg">

    </div>
    <div id="custom-next-button-vertical">
      <img src="assets/images/icons/bottom_.svg">
    </div>
  </div>
</div>
