<div class="dark_background">
</div>

<div class="default_address_dialog" *ngIf="!isMobile">
  <img src="assets/images/icons/navbar/Polygon_14.svg" class="top">
  <div class="correct_address">Верный адрес доставки?</div>
  <div class="address">
    Казань ул.Спартаковская 2
  </div>

  <div class="description mt-16">
    Мы запрашиваем адрес, чтобы в каталоге отображать товары, которые доступны к доставке в вашем районе
  </div>

  <div class="actions flex">
    <button class="main_buttons black_btn flex_center_align_center text_normal bold_700"
            (click)="closeDialog.emit(false)"
    >
      Всё верно!
    </button>
    <button class="main_buttons light_btn flex_center_align_center text_normal bold_700"
            (click)="closeDialog.emit(true)"
    >
      Сменить адрес
    </button>
  </div>
</div>

<div class="default_address_dialog_mobile" *ngIf="isMobile">
  <div class="correct_address">Заказ на этот адрес?</div>
  <div class="address">
    Казань ул.Спартаковская 2
  </div>

  <div class="actions flex">
    <button class="main_buttons light_btn flex_center_align_center text_normal bold_700"
            (click)="closeDialog.emit(true)"
    >
      Нет
    </button>
    <button class="main_buttons black_btn flex_center_align_center text_normal bold_700"
            (click)="closeDialog.emit(false)"
    >
      Да
    </button>
  </div>
</div>
