<div class="average_rate flex">
  <img [src]="'assets/images/icons/rating/'+(rateCount >3 ? 'star_rate' : 'gray_star')+'.svg'">
  <div class="flex">
    <ng-container *ngIf="rateCount > 3; else lessThanEqualThree">
      <span class="ml_8 digit bold_700 font_15">
        {{ rate | number: '0.2-2' | replaceToComma }}
      </span>
    </ng-container>
    <ng-template #lessThanEqualThree>
    <span class="ml_8 bold_700 font_15" *ngIf="rateCount === 0">
      {{ 'star_rating.no_rates' | translate }}
    </span>
      <span class="ml_8 bold_700 font_15" *ngIf="rateCount > 0 && rateCount <= 3">
      {{ 'star_rating.less_rates' | translate }}
    </span>
    </ng-template>
    <span class="rate_count bold_600 font_15">
      {{rateCount | rateCount}}
  </span>
  </div>
</div>
