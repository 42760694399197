import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

//constants
import {ConfirmationDialogInterface} from "@interfaces/confirmtion-dialog/confirmation-dialog.interface";
import {ConfirmationActionEnum} from "@interfaces/components/enums/confirmation-action.enum";
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-notification-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class ConfirmationDialogComponent {

  confirmationActionEnum = ConfirmationActionEnum;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogInterface) {
  }



  close(response: ConfirmationActionEnum): void {
    this.dialogRef.close(response)
  }

}
