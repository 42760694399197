import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {NgIf, NgClass, NgFor} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

//components
import {AddAndReduceComponent} from '../add-and-reduce/add-and-reduce.component';
import {ClearButtonComponent} from '../../global/clear-button/clear-button.component';
import {EmptyPageComponent} from "../../global/empty-page/empty-page.component";
import {BonusDisplayComponent} from "../bonus-display/bonus-display.component";

//constants
import {BasketInterface, BasketItemInterface} from "@interfaces/components/basket.interface";
import {BasketTypeEnum} from "@interfaces/orders/enums/basket.enum";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {AddAndReduceStatusEnum} from "@interfaces/components/enums/add-and-reduce-status.enum";
import {OrderInfoInterface} from "@interfaces/orders/order-info.interface";
import {ConfirmationActionEnum} from "@interfaces/components/enums/confirmation-action.enum";
import {AddToFavoriteTypeEnum as FromComponentTypeEnum} from "@interfaces/components/enums/add-to-favorite-status.enum";

// services
import {BasketService} from "@services/basket/basket.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";
import {BrowserService} from "@services/components/browser.service";
import {ComponentService} from "@services/components/component.service";

//pipes
import {PriceSpacesPipe} from "../../../pipes/string-methods/price-spaces.pipe";

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    ClearButtonComponent,
    AddAndReduceComponent,
    TranslateModule,
    PriceSpacesPipe,
    BonusDisplayComponent,
    EmptyPageComponent
  ]
})
export class BasketComponent implements OnDestroy, AfterViewInit {
  @Input() set type(type: BasketTypeEnum) {
    if (!type) {
      return;
    }
    this.basketType = type;
    if ([BasketTypeEnum.NAVBAR_BASKET,
      BasketTypeEnum.ORDER_BASKET,
      BasketTypeEnum.MOBILE_BASKET
    ].includes(this.basketType)) {
      this.subscribeForBasketUpdates();
    }
  }

  @Input() set basketPriceInfo(orderPriceInfo: OrderInfoInterface) {
    this.basketInfoCheckPromo = orderPriceInfo;
    this.getBasket(false, orderPriceInfo);
  };

  @Input() promoCode!: string | null;
  @Output() refreshBasket = new EventEmitter<void>();
  @Output() updatedBasketInfo = new EventEmitter<BasketInterface>();

  basketType!: BasketTypeEnum;
  basketTypeStatusEnums = BasketTypeEnum;
  addAndReduceTypeEnums = AddAndReduceStatusEnum;
  subscription!: Subscription;
  basket!: BasketInterface;
  isMobile!: boolean;
  basketInfoCheckPromo!: OrderInfoInterface | null;
  isTablet!: boolean;
  fromComponentTypeEnum = FromComponentTypeEnum;

  constructor(
    private basketService: BasketService,
    private globalSubscriptionService: GlobalSubscriptionService,
    private confirmationDialogService: ConfirmationDialogService,
    private browserService: BrowserService,
    private componentService: ComponentService,
    private router: Router
  ) {
    this.getBasket();
    this.isMobile = this.browserService.isMobileDevice;
    this.isTablet = this.browserService.isTabletDevice;
  }

  ngAfterViewInit(): void {
    this.componentService.scrollToTop();
  }

  subscribeForBasketUpdates(): void {
    this.subscription = this.globalSubscriptionService.getMessage()
      .subscribe((data: { type: UpdateSubscriptionTypesEnum }) => {
        if ([UpdateSubscriptionTypesEnum.UPDATE_PROFILE,
          UpdateSubscriptionTypesEnum.UPDATE_BASKET,
          UpdateSubscriptionTypesEnum.UPDATE_BASKET_FROM_ORDERS,
          UpdateSubscriptionTypesEnum.CITY_SELECTION,
        ].includes(data.type)
        ) {
          this.getBasket(UpdateSubscriptionTypesEnum.UPDATE_BASKET_FROM_ORDERS === data.type);
        }
      })
  }

  getBasket(force = false, basketInfo: OrderInfoInterface | null = null): void {
    if ((this.basketType === BasketTypeEnum.ORDER_BASKET && !basketInfo)) {
      return;
    }
    this.basketService.getBasket().subscribe((res: BasketInterface) => {
      this.basket = res;
      if (basketInfo) {
        this.basket.totalPrice = basketInfo.orderTotalPrice;
        this.basket.deliveryPrice = basketInfo.deliveryPrice;
        this.basket.productTotalPrice = basketInfo.productTotalPrice;
        this.basket.discountByPromoCode = basketInfo.discountByPromoCode;
        this.basket.discountByBonus = basketInfo.discountByBonus;
        this.basket.totalBonusAmount = basketInfo.collectedBonusAmount;
      }
      if (this.basket.basketItems.length === 0 && force) {
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET);
      }
      this.basket.basketItems.forEach((basketItem: BasketItemInterface) => basketItem.productShop.countInBasket = basketItem.count);
      this.updatedBasketInfo.emit(res);
    });
  }

  clearBasket(): void {
    this.confirmationDialogService.openConfirmationDialog({
      title: "notification.clear_bucket",
      content: "notification.sure_to_clear_bucket",
      firstButton: "notification.back",
      secondButton: "notification.clear"
    }).afterClosed().subscribe((confirm: ConfirmationActionEnum) => {
      if (confirm === ConfirmationActionEnum.APPROVE || !confirm) {
        return;
      }
      this.basketService.clearBasket().subscribe(() => {
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_CATALOG);
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET);
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_ORDERS);
      });
    })
  }

  deleteOrder(id: number): void {
    this.basketService.deleteBasketItems(id).subscribe(() => {
      this.refreshBasket.emit();
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_CATALOG);
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET_FROM_ORDERS);
      if (this.basket.basketItems.length <= 1) {
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET);
      }
    });
  }

  checkout() {
    this.router.navigate(['/order-creation'])
  }

  ngOnDestroy(): void {
    // this.subscription?.unsubscribe();
  }
}
