import {Pipe, PipeTransform} from '@angular/core';
//constants
import {PriceInterface} from "@interfaces/catalog/price.interface";
import {FiltrationFieldsInterface} from "@interfaces/catalog/products.interface";

@Pipe({
    name: 'filterCondition', pure: false,
    standalone: true
})
export class FilterConditionPipe implements PipeTransform {
  // TODO make this functional more dynamic
  transform(filterData: FiltrationFieldsInterface, priceRange?: PriceInterface): boolean {
  if(!filterData.flowerIds || !filterData.colorIds){return false;}
    return (
      filterData?.flowerIds?.length > 0 ||
      filterData.colorIds.length > 0 ||
      priceRange?.startPrice !== filterData.startPrice ||
      priceRange?.endPrice !== filterData.endPrice
    );

  }
}
