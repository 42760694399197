<div class="laptop_padding bg_color">
  <div class="container">
    <div class="info_links w_100  middle_small_text bold">
      <div class="add_shop flex_start_align_center">
        <img src="assets/images/icons/navbar/star.svg">
        <div class="ml_8 text_gray_color font_15 pointer" (click)="navigateToSellerLoginPage()">{{'navbar.want_to_add_store' | translate}}</div>
      </div>

      <div class="where_to_deliver_container flex_align_center font_15 bold_700">
        <img src="assets/images/icons/marker-pin.svg">
        <span
          class="ml_8 bold_700">{{selectedAddressName ? ('navbar.delivery' | translate) : ('navbar.where_do_we_deliver' | translate)}}&nbsp;&nbsp;-&nbsp;&nbsp;</span>
        <span class="pointer bold_700"
              (click)="selectAddressToDeliverEvent.emit(true)">
          <u>{{selectedAddressName || ('navbar.select_address' | translate) }}</u>
        </span>
        <app-show-default-address-to-change
          *ngIf="defaultAddressIsSelected"
          (closeDialog)="handleDialogClose($event)"
        ></app-show-default-address-to-change>
      </div>

    </div>
  </div>
</div>

