import {afterNextRender, Component, EventEmitter, Output} from '@angular/core';
import {BrowserService} from "@services/components/browser.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-show-default-address-to-change',
  standalone: true,
  imports: [NgIf],
  templateUrl: './show-default-address-to-change.component.html',
  styleUrl: './show-default-address-to-change.component.scss'
})
export class ShowDefaultAddressToChangeComponent {
  @Output() closeDialog = new EventEmitter<boolean>();
  isMobile = false;

  constructor(
    private browserService: BrowserService
  ) {
    afterNextRender(() => {
      this.isMobile = this.browserService.isMobileDevice;
    })

  }
}
