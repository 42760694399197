<div class="content">
  <div class="close_button">
    <app-clear-button (closeButton)="close(false)"></app-clear-button>
  </div>
  <div class="title bold_800">
    Маленький нюанс ☝🏻😔
  </div>
  <div class="description font_15 bold mt-16">
    Кажется выбранный вами магазин не доставляет по данному адресу, давайте мы подберём вам товары исходя из нового адреса доставки
  </div>
  <div class="flex_center_align_center mt_30">
    <button class="confirm main_buttons black_btn flex_center_align_center" (click)="close(true)">
      Показать товары по адресу! ✨
    </button>
  </div>
  <div class="flex_center_align_center mt-16 pointer" (click)="close(false)">
    <div class="font_15 bold">
      Укажу другой адрес для доставки
    </div>
    <img class="ml_4" src="assets/images/icons/arrow_narrow_right.svg">
  </div>
</div>
