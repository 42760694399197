<div class="city_select_container flex_column_between br_16">
  <div class="search flex_column"  [ngClass]="{mobile_search:cityData.isMobile}">
    <span class="bold_700 text_normal_sub mb_20" *ngIf="!cityData.isMobile">{{'select_city' | translate}}</span>
    <div class="app_input">
      <div class="search_icon pointer">
        <img src="assets/images/icons/search-icon.svg">
      </div>
      <input type="text" placeholder="{{'input_placeholder.choose_city' | translate}}"
             [(ngModel)]="searchedValue"
             (ngModelChange)="getCities()">
    </div>
  </div>

  <div class="drop_down text_normal flow_y_auto">
    <div class="options pointer" *ngFor="let city of arrayOfSearchedCity;let i=index"
         [ngClass]="{'selected_icon': city.id == cityData.selected}"
         (click)="selectCity(city)">
      <span *ngIf="!cityData.isMobile;else mobileCitySelect">{{city.name}}</span>
      <ng-template #mobileCitySelect>

        <label class="radio bold text_normal_sub pointer mb_16 ">
            {{city.name}}
          <input type="radio" class="pointer" name="city"
                 [value]="city.id"
                 [(ngModel)]="selectedCityId"
                 (ngModelChange)="changeSelected()"
          >
          <span class="checkmark" [ngClass]="{checked: (city.id === selectedCityId) || city.id == cityData.selected}">
              <img src="assets/images/icons/check.svg">
          </span>
        </label>
      </ng-template>
    </div>
  </div>
</div>

