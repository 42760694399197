<div class="add_to_favorite_button pointer"
     [ngClass]="{'transparent':classTransparent}"
     (click)="addToFavorites($event)">
  <div *ngIf="liked;else unLikeProduct" class="active_heart">
    <img [src]="classTransparent ? 'assets/images/active-heart.svg':'assets/images/active-heart-gray.svg'">
  </div>
  <ng-template #unLikeProduct>
    <img [src]="classTransparent ? 'assets/images/heart.svg':'assets/images/heart-gray.svg'">
  </ng-template>
</div>
