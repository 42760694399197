import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GlobalSubscriptionService {
  private subject = new Subject<any>();
  // private swiperIndexChangeSource = new Subject<number>();
  // indexChange$ = this.swiperIndexChangeSource.asObservable();
  //
  // notifyIndexChange(newIndex: number): void {
  //   this.swiperIndexChangeSource.next(newIndex);
  // }

  sendMessage(type: string, message?: any): void {
    this.subject.next({message, type});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}
