<div class="empty_page_container flex_center_align_center w_100" [ngStyle]="{'height' : settings.fullWidth ? '100vh': '100%'}">
  <div class="content flex_column_center_align_center text_center">
    <div class="title text_title_36 bold_800 mb_20">
      {{'empty_page.' + settings.title | translate}}
    </div>
    <div class="description font_17 bold">
      {{'empty_page.' + settings.description | translate}}
    </div>
    <button *ngIf="settings.button && settings.text"
            class="main_buttons empty_page_button flex_center_align_center font_15 bold_600 mt_40"
            (click)="outputDataEvent.emit()">
      {{'empty_page.' + settings.text | translate}}
    </button>
  </div>
</div>
