import {Component} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-error-message',
    template: `
    <div class="error w_100 br_16 flex_center_align_center mt_15 text_normal">
      <span class="bold_700">{{'auth.attention' | translate}}</span>
      <span class="ml_8">{{'auth.error_message' | translate}}</span>
    </div>`,
    styleUrls: ['./error-message.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class ErrorMessageComponent {
}
