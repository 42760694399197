<div class="parent_map_container position_relative">
  <div class="favorites flex_between_align_center" *ngIf="configs.type == mapConfigsType.MOBILE">
    <div class="favorite_address_block pointer flex_column_center ml_8"
         *ngFor="let elem of favoriteAddress"
         (click)="chooseFavoriteAddressEmitter.emit(elem)"
    >
      <div class="full_address_label bold small_text text_gray_color">
        {{elem.fullAddress}}
      </div>
    </div>
  </div>

  <div class="input_search flex_between_align_center"
       *ngIf="configs.type!==mapConfigsType.STANDARD">

    <div class="search_for_address_block">
      <app-search-location *ngIf="configs.type == mapConfigsType.EXTRA"
                           (getLocation)="handleNewLocation($event)"
                           [locations] = "locations"
                           (searchLocationEmitter)="searchLocation($event)"
      ></app-search-location>

      <div class="app_input"
           *ngIf="configs.type == mapConfigsType.MOBILE"
           (click)="openSearchComponent = true">
        <img src="../../../../../assets/images/icons/search-icon.svg" class="icon pointer">
        <input type="text"
               placeholder="{{'input_placeholder.type_your_address' | translate}}"
               readonly
               (keydown)="$event.preventDefault()"
        >
      </div>

      <div class="custom_geolocation_icon pointer br_16"
           *ngIf="configs.type == mapConfigsType.MOBILE"
           (click)="setCenterOnBtnClick()">
        <img src="../../../../../assets/images/icons/orders/geolocation.svg">
      </div>
    </div>

    <button class="confirm main_buttons black_btn flex_center_align_center"
            [ngClass]="{'gray_btn' : (radiusError || !fullAddressObject?.fullAddress)}"
            [disabled]="fullAddressObject?.fullAddress && radiusError"
            (click)="confirm()">
      {{'buttons.confirm' | translate}}
    </button>
  </div>

  <div #mapDiv [id]="configs.id" class="mt_15 map"
       [ngClass]="{extra_map:configs.type == mapConfigsType.EXTRA ,mobile_map: configs.type == mapConfigsType.MOBILE }"
       (click)="openMapDialog()"></div>

  <div class="address_hint text_center flex_colum_end"
       *ngIf="configs.type == mapConfigsType.EXTRA || configs.type == mapConfigsType.MOBILE">
    <div class="radius_error br_20 text_normal_sub bold mb_16" *ngIf="radiusError">
      {{'order_creation.address_out_of_radius' | translate}}
    </div>
    <div class="address_label" *ngIf="fullAddressObject?.fullAddress">
      {{fullAddressObject?.fullAddress}}
      <div class="seller_price font_15" *ngIf="calledFrom === 'ORDER'">
        <span *ngIf="delivery !== null, else empty">
          {{ 'filter_by.delivery' | translate }} {{delivery?.deliveryPrice}}₽
        </span>
        <ng-template #empty>
          {{'empty_state.incorrect_address' | translate}}
        </ng-template>
      </div>
      <img style="position: absolute;
       left: 50%;
    transform: translate(-50%, 0);
    bottom: -6px;
"  src="../../../../../assets/images/icons/arrow_white.svg">
    </div>
  </div>
  <div class="placemark"></div>
</div>

<ng-container *ngIf="openSearchComponent">
  <div class="search_container open_component">
    <app-search-location [mobileSearch]="true"
                         [locations]="locations"
                         (closeComponentEmitter)="openSearchComponent=false"
                         (searchLocationEmitter)="searchLocation($event)"
                         (getLocation)="handleNewLocation($event)"
    ></app-search-location>
  </div>
</ng-container>
