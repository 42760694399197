import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
//Services
import {SsrCookieService} from "ngx-cookie-service-ssr";
//Constants
import {CityInterface} from "@interfaces/components/cities.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {ProductsFiltrationInterface} from "@interfaces/catalog/products.interface";
import {CityOrAddressSelectionInterface} from "@interfaces/catalog/filtration-default-state.const";
import {ShopsFiltrationInterface} from "@interfaces/catalog/shops.interface";
import {Observable} from "rxjs";
import {RequestMethodsService} from "@services/request/request-methods.service";
import { Request } from 'express';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private isBrowser: boolean;
  private request: Request;

  constructor(
    private ssrCoockieService: SsrCookieService,
    private requestService: RequestMethodsService,
    @Optional() @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject('REQUEST') request: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.request = request;
  }

  cityModel = 'catalogAddressOrCity';

  setCityId(cityModel: { address: FavoriteAddressInterface } | { city: CityInterface }): void {
    this.clearCityFromStorage();
    this.ssrCoockieService.set(this.cityModel, JSON.stringify(cityModel), {
      path: '/',
      sameSite: 'Strict'
    })
  }

  clearCityFromStorage(): void {
    this.ssrCoockieService.deleteAll(this.cityModel);
  }

  getCityIdFromLocalStorage(): { address: FavoriteAddressInterface } | { city: CityInterface } | null {
    try {
      const json = this.isBrowser ? this.ssrCoockieService.get(this.cityModel) : this.request.cookies[this.cityModel];
      if (!json) {
        return null;
      }
      return JSON.parse(json);
    } catch (e: any) {
      return null;
    }
  }

  getCityName(): string {
    try {
      const address = this.getCityIdFromLocalStorage();
      if (!address) { return 'Казань'; }
      if (('city' in address )) {
        return address.city.name;
      } else {
        return address.address.cityName || 'Казань'
      }

    } catch (e: any) {
      return '';
    }
  }

  getMinimumPriceForSelectedAddress(): Observable<any> | null {
    const selectedAddress = this.getObjectCityOrAddress();
    if (!selectedAddress) { return null; }
    return this.requestService.post('user/meta/information', selectedAddress)
  }

  getCityModelFromLocalStorage(): { address: FavoriteAddressInterface } | { city: CityInterface } | any {
    try {
      const json = this.getCityIdFromLocalStorage();
      if (!json) {
        return null;
      }
      return json;
    } catch (e: any) {
      return null;
    }
  }

  getDefaultAddress(): { address: FavoriteAddressInterface } {
    return {
      address: {
        cityName: "Казань",
        comment: "",
        fullAddress: "Казань ул.Спартаковская 2",
        latitude: 55.779474,
        longitude: 49.128126
      }
    }
  }

  getDefaultAddressLatLng(): CityOrAddressSelectionInterface {
    const address = this.getDefaultAddress();
    return {
      latitude: address.address.latitude,
      longitude: address.address.longitude,
    }
  }

  deleteCityOrAddressFromFilterData(filterData: ProductsFiltrationInterface | ShopsFiltrationInterface): any {
    const modelCityOrAddress = this.getCityModelFromLocalStorage();
    if (modelCityOrAddress && filterData.filter) {
      delete filterData.filter.longitude;
      delete filterData.filter.latitude;
      delete filterData.filter.cityId;
      if ('address' in modelCityOrAddress) {
        filterData.filter.latitude = +modelCityOrAddress.address.latitude
        filterData.filter.longitude = +modelCityOrAddress.address.longitude
      } else {
        filterData.filter.cityId = modelCityOrAddress.city.id;
      }
    }
    return filterData
  }

  getObjectCityOrAddress(): CityOrAddressSelectionInterface | null {
    const cityOrAddress: CityOrAddressSelectionInterface = {};
    const modelCityOrAddress = this.getCityModelFromLocalStorage();
    if (modelCityOrAddress) {
      if ('address' in modelCityOrAddress) {
        cityOrAddress.latitude = +modelCityOrAddress.address.latitude
        cityOrAddress.longitude = +modelCityOrAddress.address.longitude
      } else {
        cityOrAddress.cityId = modelCityOrAddress.city.id;
      }
    } else {
      return this.getDefaultAddressLatLng()
    }
    return cityOrAddress
  }

}
