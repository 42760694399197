import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {NgClass, NgIf, NgFor} from '@angular/common';
//constants
import {ResourceUrlInterface} from "@interfaces/components/resource-url.interface";
import {SwiperTypeEnum} from "@interfaces/components/enums/swiper-type.enum";
//services
import {BrowserService} from "@services/components/browser.service";

@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor]
})
export class ProductGalleryComponent implements AfterViewInit {
  @ViewChild('imageContainer', {static: true}) imageContainerRef!: ElementRef;
  @Input() img_data!: Array<ResourceUrlInterface>;
  @Input() numOfColumns!: number
  @Input() type: SwiperTypeEnum = SwiperTypeEnum.PRODUCT_CARD;
  @Output() navigateToSinglePageEmitter = new EventEmitter<void>();
  pending: boolean = true;
  isMobile!: boolean;
  swiperTypeEnum = SwiperTypeEnum;

  constructor(private router: Router, private browserService: BrowserService) {
    this.isMobile = this.browserService.isMobileDevice
  }

  ngAfterViewInit(): void {
    const element = this.imageContainerRef.nativeElement;
    if ((this.router.url == '/' || this.router.url == '/favorites') && element) {
      element.style.height = `${element.clientWidth}px`;
    }
  }
}
