<div class="app_input mt_3 bold_600 font_15" *ngIf="!isMobile">
  <mat-select [panelClass]="'big_panel sort_By'"
              [(ngModel)]="sortBy"
              (selectionChange)="onSelectChange()">
    <mat-radio-group [(ngModel)]="sortBy.id">
      <mat-option *ngFor="let item of sorting" [value]="item" class="text_normal">
        <mat-radio-button [value]="item.id"
                          class="sortBy_radio">
          <span class="bold font_15" [ngClass]="{'bold_700':item.id==sortBy.id}">{{'sort_by.sort_by' | translate}}</span>
          <span class="bold font_15"
                [ngClass]="{'bold_700':item.id==sortBy.id}">{{('sort_by.' + item.text) | translate}}</span>
        </mat-radio-button>
      </mat-option>
    </mat-radio-group>

  </mat-select>
</div>

<div class="radio_margin" *ngIf="isMobile">
  <mat-radio-group [(ngModel)]="sortBy" class="flex_column_between">
    <mat-radio-button *ngFor="let item of sorting"
                      [value]="item"
                      (change)="sortBy = $event.value;onSelectChange()"
                      class="options"
    >
          <span class="bold font_15"
                [ngClass]="{'bold_700':item.id==sortBy?.id}">{{'sort_by.sort_by' | translate}}</span>
      <span class="bold font_15"
            [ngClass]="{'bold_700':item.id==sortBy?.id}">{{('sort_by.' + item.text) | translate}}</span>
    </mat-radio-button>
  </mat-radio-group>
</div>
