import {Injectable} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {
  StandardBottomSheetComponent
} from "../../partials/shared-modules/global/standard-bottom-sheet/standard-bottom-sheet.component";

@Injectable({
  providedIn: 'root'
})
export class BottomSheetDialogService {

  constructor(private bottomSheet: MatBottomSheet) {
  }

  openBottomSheet(title: string, component: any, info?: any, disableClose?: boolean,className? : string): MatBottomSheetRef<StandardBottomSheetComponent> {
    return this.bottomSheet.open(StandardBottomSheetComponent, {
      data: {
        title,
        component,
        info,
        className
      },
      disableClose
    });
  }
}
