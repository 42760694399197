import {afterNextRender, Component, Input} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DialogModule} from "@angular/cdk/dialog";
import {NgClass} from "@angular/common";

//Components
import {CityDropDownComponent} from "./top-navbar/city-drop-down/city-drop-down.component";
import {DeliveryAddressComponent} from "./top-navbar/delivery-address/delivery-address.component";
import {StarRatingComponent} from "../../../modules/user/star-rating/star-rating.component";
import {TopNavbarComponent} from './top-navbar/top-navbar.component';
import {BottomNavbarComponent} from './bottom-navbar/bottom-navbar.component';
import {BannerComponent} from "./banner/banner.component";

//Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {CityService} from "@services/city/city.service";
import {ComponentService} from "@services/components/component.service";
import {BrowserService} from "@services/components/browser.service";
import {BottomSheetDialogService} from "@services/bottom-sheet-dialog/bottom-sheet-dialog.service";
import {AuthService} from "@services/auth/auth.service";
import {BasketService} from "@services/basket/basket.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";
import {IsInGeofenceService} from "@services/is-in-geofence/is-in-geofence.service";

//Constants
import {CityInterface} from "@interfaces/components/cities.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {ConfirmationActionEnum} from "@interfaces/components/enums/confirmation-action.enum";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {OrderRateInterface} from "@interfaces/components/star-rating.interface";
import {MobileMapComponent} from "../address-selection/mobile-map/mobile-map.component";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  providers: [ComponentService],
  imports: [TopNavbarComponent, BottomNavbarComponent, DialogModule, BannerComponent, NgClass, MobileMapComponent]
})
export class NavbarComponent {
  @Input() scrolled!: boolean;
  cities!: Array<CityInterface>;
  cityControl!: { address: FavoriteAddressInterface } | { city: CityInterface };
  subscription!: Subscription;
  defaultAddressIsSelected = false;
  openComponentForMobileMap = false;

  constructor(
    protected requestService: RequestMethodsService,
    protected globalSubscriptionService: GlobalSubscriptionService,
    protected cityService: CityService,
    protected activatedRoute: ActivatedRoute,
    protected componentService: ComponentService,
    protected browserService: BrowserService,
    protected dialog: MatDialog,
    protected bottomSheetService: BottomSheetDialogService,
    protected authService: AuthService,
    protected router: Router,
    protected basketService: BasketService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected mapService: IsInGeofenceService
  ) {
    afterNextRender(() => {
      this.checkUserOrderForRating();
      this.detectCurrentCityOrAddress();
      this.globalSubscriptionService.getMessage().subscribe((data: { type: string, message: any }) => {
        if (data.type === UpdateSubscriptionTypesEnum.MOBILE_MAP_EMIT) {
          if (data.message) {
            this.onSelectChange({address: data.message})
          }
        } else if (data.type === UpdateSubscriptionTypesEnum.OPEN_ADDRESS_SELECTION) {
          this.openDialogOrBottomSheetAddressSelect(true);
        }
      })
    })
  }

  detectCurrentCityOrAddress(): void {
    const data = this.cityService.getCityIdFromLocalStorage();
    if (data) {
      this.cityControl = data;
    } else {

      this.cityControl = this.cityService.getDefaultAddress();
      this.defaultAddressIsSelected = true;
      this.updateAddressOrCityInLocalStorageAndMakeEmitToUpdate(this.cityControl, false)
    }
  }

  openDialog(): void {
    this.browserService.isMobileDevice ? this.openAsCityBottomSheet() : this.openAsCityDialog();
  }

  openDialogOrBottomSheetAddressSelect(openSelectionDialog: boolean): void {
    this.defaultAddressIsSelected = false;
    if (openSelectionDialog) {
      this.browserService.isMobileDevice ? this.openAsBottomSheetForSelectAddress() : this.openAsDialogForSelectAddress();
    }
  }

  openAsDialogForSelectAddress(): void {
    const dialogRef = this.dialog.open(DeliveryAddressComponent, {
      data: {
        selectedAddress: this.cityControl && 'address' in this.cityControl ? this.cityControl.address : null,
      },
      disableClose: !this.cityService.getCityIdFromLocalStorage()
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data && data.fullAddress) {
        this.onSelectChange({address: data})
      } else if (data) {
        this.openAsCityDialog()
      }
    })

  }

  openAsBottomSheetForSelectAddress(): void {
    const bottomSheetRef = this.bottomSheetService.openBottomSheet(
      '',
      DeliveryAddressComponent,
      {
        selectedAddress: this.cityControl && 'address' in this.cityControl ? this.cityControl.address : null,
      },
      !this.cityService.getCityIdFromLocalStorage(),
      'big_height'
    );
    bottomSheetRef.afterDismissed().subscribe((data: { favoriteAddress: FavoriteAddressInterface | null, openMobileMap: boolean }) => {
      if (!data) {
        return;
      }
      const {favoriteAddress, openMobileMap} = data;

      if (!favoriteAddress && !openMobileMap) {
        this.openAsCityBottomSheet();
      } else if (openMobileMap) {
        this.openComponentForMobileMap = true;
      } else if (favoriteAddress) {
        this.onSelectChange({address: favoriteAddress});
      }
    });
  }

  closeMobileMap(address: FavoriteAddressInterface): void {
    this.openComponentForMobileMap = false;
    this.updateAddressOrCityInLocalStorageAndMakeEmitToUpdate({address: address}, true)
  }

  openAsCityDialog(): void {
    const data: any = {};
    const dialogRef = this.dialog.open(CityDropDownComponent, {
      disableClose: !this.cityService.getCityIdFromLocalStorage(),
      data: {...data, selected: this.cityControl && 'city' in this.cityControl ? this.cityControl.city.id : null}
    });
    dialogRef.afterClosed().subscribe((city: CityInterface) => {
      if (!city) {
        return
      }
      this.onSelectChange({city: city})
    })
  }

  openAsCityBottomSheet(): void {
    const bottomSheetRef = this.bottomSheetService.openBottomSheet(
      'select_city',
      CityDropDownComponent,
      {
        selected: this.cityControl && 'city' in this.cityControl ? this.cityControl.city.id : null,
        isMobile: this.browserService.isMobileDevice
      },
      !this.cityService.getCityIdFromLocalStorage(),
      'big_height'
    );
    bottomSheetRef.afterDismissed().subscribe((result) => {
      if (!result) {
        return
      }
      this.onSelectChange({city: result})
    });
  }

  onSelectChange(city: { address: FavoriteAddressInterface } | { city: CityInterface }): void {
    if (this.authService.checkToken()) {
      this.userModeCheckAvailability(city)
    } else {
      this.updateAddressOrCityInLocalStorageAndMakeEmitToUpdate(city);
    }
  }

  userModeCheckAvailability(city: { address: FavoriteAddressInterface } | { city: CityInterface }): void {
    this.basketService.checkBasketAvailabilityForNewAddress(city).subscribe((response: boolean) => {
      if (response) {
        this.updateAddressOrCityInLocalStorageAndMakeEmitToUpdate(city);
      } else {
        this.clearBasketForShop(city)
      }
    })
  }

  clearBasketForShop(city: { address: FavoriteAddressInterface } | { city: CityInterface }): void {
    this.confirmationDialogService.openConfirmationDialog(
      {
        title: 'notification.clear_bucket',
        content: 'notification.change_address_clear_all',
        firstButton: 'notification.back',
        secondButton: 'notification.clear',
      }).afterClosed().subscribe((res: ConfirmationActionEnum) => {
      if (res === ConfirmationActionEnum.APPROVE || !res) {
        return;
      }
      this.basketService.clearBasket().subscribe(() => {
        this.updateAddressOrCityInLocalStorageAndMakeEmitToUpdate(city);
      });
    })
  }

  updateAddressOrCityInLocalStorageAndMakeEmitToUpdate(
    city: { address: FavoriteAddressInterface } | { city: CityInterface },
    navigation = true
  ): void {
    this.cityControl = city;
    if ('address' in this.cityControl && !this.cityControl.address.cityName) {
      this.mapService.getCityName(this.cityControl.address.latitude, this.cityControl.address.longitude).subscribe(cityName => {
        if ('address' in this.cityControl) {
          this.cityControl.address.cityName = cityName;
          this.cityService.setCityId(this.cityControl);
        }
      })
    } else {
      this.cityService.setCityId(this.cityControl);
    }

    if (navigation) {
      setTimeout(() => {
        window.location.href = '/products';
      }, 300)
    }
  }

  checkUserOrderForRating(): void {
    if (!this.authService.checkToken()) {
      return;
    }

    this.requestService.get(`${EndpointConstant.orders}/${EndpointConstant.rate}/${EndpointConstant.all}`)
      .subscribe((arrayOfRates: Array<OrderRateInterface>) => {
        if (!arrayOfRates.length) {
          return;
        }
        this.openStarRatingComponent(arrayOfRates[0].id)
      })
  }

  openStarRatingComponent(orderId: number): void {
    const starRateDialog = this.dialog.open(StarRatingComponent, {
        width: this.browserService.isMobileDevice ? '330px' : '512px',
        height: 'auto',
        data: {orderId}
      }
    );
    starRateDialog.afterClosed().subscribe((res: any) => {
      if (res) {
        return;
      }
      this.skipOrderRate(orderId);
    })
  }

  skipOrderRate(orderId: number): void {
    this.requestService.put(`${EndpointConstant.orders}/${EndpointConstant.rate}/${orderId}/${EndpointConstant.skip}`, {})
      .subscribe(() => {
      })
  }
}
