<div class="open_component">
  <app-map
           [configsForMap]="{id:'map_dialog1',type:mapConfigType}"
           [city]="city"
           [data]="mapData"
           (openConfirmationDialogForAddressEmitter)="openConfirmationDialogForAddress($event)"
           (isMobileMapEmitter)="closeMapComponentEmitter.emit($event)"
  >
  </app-map>
</div>
