import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {NgIf} from '@angular/common';
//Components
import {MapComponent} from '../map/map.component';
//Constants
import {MapTypeEnum} from "@interfaces/orders/enums/map-configs.enum";
import {CityInterface} from "@interfaces/components/cities.interface";
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
//Services
import {CityService} from "@services/city/city.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {OrderInfoInterface} from "@interfaces/orders/order-info.interface";

@Component({
  selector: 'app-mobile-map',
  templateUrl: './mobile-map.component.html',
  styleUrls: ['./mobile-map.component.scss'],
  standalone: true,
  imports: [NgIf, MapComponent]
})
export class MobileMapComponent {
  @Input() checkOrder!:OrderInfoInterface;
  @Output() closeMapComponentEmitter = new EventEmitter<FavoriteAddressInterface>();
  @Input() favoriteAddress!: FavoriteAddressInterface
  @Input() mapData!: any
  mapConfigType = MapTypeEnum.MOBILE;
  city!: CityInterface | FavoriteAddressInterface;
  openConfirmAddressComponent = false;
  getFullAddress!: { fullAddressObject: FavoriteAddressInterface };


  constructor(
    private cityService: CityService,
    private router: Router,
    private globalSubscriptionService: GlobalSubscriptionService,
  ) {
    const city = this.cityService.getCityModelFromLocalStorage();
    if (city) {
      this.city = 'city' in city ? city.city : city.address;
    }
  }

  openConfirmationDialogForAddress(data: { fullAddressObject: FullAddressLongLatInterface }): void {
    if (data) {
      this.getFullAddress = {...data}
      this.openConfirmAddressComponent = true;
    }
  }

  closeComponent(event?: any): void {
    const data = {...event}
    if (this.openConfirmAddressComponent || this.getFullAddress.fullAddressObject.id) {
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.MOBILE_MAP_EMIT, data)
      this.router.navigate(['/'])
    }
  }

}
