<div class="small_screen_container position_relative flex_column_center_align_center">
  <div class="small_screen_container--first_section flex_column_center_align_center">

    <div class="small_screen_container--logo">
      <img src="assets/images/logo.svg">
    </div>

    <div class="small_screen_container--greetings flex_column_center_align_center">
    <span class="text_title_big bold_800">
       {{"mobile_tablet.hi_friend" | translate }}
    </span>
      <span class="info text_title bold">
       {{"mobile_tablet.info" | translate }}
    </span>
      <span class="download_in bold">
       {{"mobile_tablet.accessible" | translate }}
    </span>
    </div>

    <button class="small_screen_container--button main_buttons bold_700 mt_40" (click)="openDownloadLink()">
      {{"mobile_tablet.download_app" | translate }}
    </button>
  </div>

  <div class="image">

    <div class="image--parent position_relative flex_center_align_center">
      <img src="assets/images/small-screen-bg.svg" class="w_100">

      <div class="download_icons flex_between_align_center">
        <div>
          <div class="application_icon pointer">
            <img src="assets/images/icons/app-sore-black.svg">
          </div>
          <div class="application_icon mt_15 pointer">
            <img src="assets/images/icons/google-play-black.svg">
          </div>
          <div class="application_icon mt_15 pointer">
            <img src="assets/images/icons/huawei-black.svg">
          </div>
        </div>
        <div class="ml_25 pointer flex_center_align_center w_50">
          <img class="w_100" src="assets/images/icons/small-screen-qr-code.svg">
        </div>
      </div>
    </div>
  </div>
</div>
