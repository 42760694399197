import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
// Constants
import {PaginationResponseInterface} from "@interfaces/global/pagination.interface";
//services
import {BrowserService} from "@services/components/browser.service";
import {TranslateModule} from '@ngx-translate/core';
import {NgIf, NgClass, NgFor} from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, TranslateModule]
})
export class PaginationComponent implements OnChanges {
  @Input() pagination!: PaginationResponseInterface;
  @Input() isMobile!: boolean;
  @Output() pageEvent = new EventEmitter<number>();
  @Output() contentSizeEvent = new EventEmitter<number>();
  activePage!: number;
  rangeDiapason = 3;
  count!: number;
  pageList: Array<number> = [];

  constructor(private browserService: BrowserService) {
    if (this.browserService.isMobileDevice) {
      this.rangeDiapason = 1
    }
    this.count = (this.rangeDiapason * 2) + 1;
  }

  ngOnChanges() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.force) {
      this.changePage(1, true);
    }
  }

  changePage(page = 0, fake = false): void {

    this.activePage = page ? page : this.activePage + 1;
    let endIndex;
    let startIndex;

    if (
      (this.activePage >= this.rangeDiapason + 1) &&
      (this.activePage <= this.pagination.totalPages) &&
      (this.pagination.totalPages > (this.rangeDiapason + 1))
    ) {
      if ((this.activePage + this.rangeDiapason) > this.pagination.totalPages) {
        endIndex = this.pagination.totalPages;
        startIndex = Math.abs(this.pagination.totalPages - this.rangeDiapason * 2)
      } else {
        endIndex = (this.activePage + this.rangeDiapason);
        startIndex = this.activePage - this.rangeDiapason;
      }
      this.makePaginationList(startIndex, endIndex);

    } else {
      let end = (this.count >= this.pagination.totalPages) ? this.pagination.totalPages : this.count;
      this.makePaginationList(1, end);
    }

    if (!fake) {
      this.pageEvent.emit(page);
    }
  }

  makePaginationList(startIndex: number, endIndex: number): void {
    this.pageList = [];
    for (let i = startIndex; i <= endIndex; i++) {
      this.pageList.push(i);
    }
  }

  changePageWithArrow(increment = false): void {
    if ((this.activePage === 1 && !increment) || (this.activePage === this.pagination.totalPages) && increment) {
      return;
    }
    const page = this.activePage + (increment ? 1 : -1);
    this.changePage(page);
  }
}
