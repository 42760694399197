import {AfterViewInit, Component} from '@angular/core';
//constants
import {CityInterface} from "@interfaces/components/cities.interface";
import {CityService} from "@services/city/city.service";
import {BasketTypeEnum} from "@interfaces/orders/enums/basket.enum";
//services
import {ComponentService} from "@services/components/component.service";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
//components
import {BasketComponent} from '../basket/basket.component';
import {PopularProductsComponent} from "../new-catalog/popular-products/popular-products.component";

@Component({
  selector: 'app-mobile-basket',
  templateUrl: './mobile-basket.component.html',
  styleUrls: ['./mobile-basket.component.scss'],
  standalone: true,
  imports: [BasketComponent, PopularProductsComponent]
})
export class MobileBasketComponent implements AfterViewInit {
  city!: FavoriteAddressInterface | CityInterface;
  basketTypeEnum = BasketTypeEnum;

  constructor(private cityService: CityService, private componentService: ComponentService) {
    const city = this.cityService.getCityModelFromLocalStorage();
    if (city) {
      this.city = 'address' in city ? city.address : city.city;
    }
  }

  ngAfterViewInit(): void {
    this.componentService.scrollToTop();
  }


}
