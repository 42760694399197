<div class="pagination_container" *ngIf="pagination.totalPages > 1">
  <div class="pages">
    <ng-container *ngIf="!isMobile; else mobileArrow">
      <div class="pages--arrow flex_align_center bold_600 pointer"
           *ngIf="activePage !== 1"
           (click)="changePageWithArrow(false)"
      >
        <img src="assets/images/icons/pagination/page-left-arrow.svg">
        <span class="ml_8">
         {{'pagination.previous_page' | translate}}
       </span>
      </div>
    </ng-container>

    <ng-template #mobileArrow>
      <div class="pages--arrow flex_between_align_center"
           [ngClass]="{'disable': activePage === 1}">
        <img src="assets/images/icons/pagination/double-arrow-left.svg" class="pointer"
             (click)="changePage(1)">
        <img src="assets/images/icons/pagination/arrow-left.svg" class="pointer"
             (click)="changePageWithArrow(false)">
      </div>
    </ng-template>
    <div class="pages-parent flex_center_align_center">
      <div class="pages-parent--item pointer flex_center_align_center font_17 bold br_50"
           [ngClass]="{'active_page bold_800': page == activePage}"
           *ngFor="let page of pageList"
           (click)="changePage(page)">
        {{page}}
      </div>
    </div>
    <ng-container *ngIf="!isMobile; else mobileDesign">
      <div class="pages--arrow  flex_align_center bold_600 pointer"
           *ngIf="activePage !== pagination.totalPages"
           (click)="changePageWithArrow(true)"
      >
         <span>
          {{'pagination.next_page' | translate}}
         </span>
        <img src="assets/images/icons/pagination/page-right-arrow.svg" class="ml_8">
      </div>
    </ng-container>

    <ng-template #mobileDesign>
      <div class="pages--arrow  flex_between_align_center"
           [ngClass]="{'disable': activePage === pagination.totalPages}">
        <img src="assets/images/icons/pagination/arrow-right.svg" class="pointer"
             (click)="changePageWithArrow(true)">
        <img src="assets/images/icons/pagination/double-arrow-right.svg" class="pointer"
             (click)="changePage(pagination.totalPages)"
        >
      </div>
    </ng-template>

  </div>
</div>
