import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
//service
import {BrowserService} from "@services/components/browser.service";

@Injectable({
  providedIn: "root"
})

export class MobileGuard  {
  constructor(private browserService: BrowserService) {
  }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.browserService.getIsDesktop()
  }
}
