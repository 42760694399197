import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'rateCount',
  standalone: true
})
export class RateCountPipe implements PipeTransform {

  transform(
    count: number
  ): string {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if (count < 1000) {
      if (count === 0) {
        return `(${count})`;
      }
      if (lastTwoDigits >= 10 && lastTwoDigits <= 20) {
        return `(${count} оценок)`;
      }
      if (lastDigit === 1) {
        return `(${count} оценка)`
      } else if (lastDigit >= 2 && lastDigit <= 4) {
        return `(${count} оценки)`
      } else {
        return `(${count} оценок)`
      }
    } else {
      const thousands = Math.floor(count / 1000);
      return `(${thousands}тыс.)`
    }
  }
}
