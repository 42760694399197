<div class="navigation_container">
  <ul>
    <li class="font_15 bold_600">
      <a href="/products">{{'navigation.generalPage' | translate}}</a>
      <span>/</span>
    </li>
    <li *ngFor="let navigation of navigationData; let last = last" class="font_15 bold_600">
      <ng-container *ngIf="!last">
        <a [href]="navigation.url">{{navigation.label | translate | titlecase}}</a>
        <span>/</span>
      </ng-container>
      <ng-container *ngIf="last">
        <div class="last_label">{{navigation.label | translate | titlecase}}</div>
      </ng-container>
    </li>
  </ul>
</div>
