import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MatOption} from '@angular/material/core';
import {MatAutocompleteTrigger, MatAutocomplete} from '@angular/material/autocomplete';
import {NgClass, NgIf, NgFor} from '@angular/common';

// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {IsInGeofenceService} from "@services/is-in-geofence/is-in-geofence.service";
import {CityService} from "@services/city/city.service";
import {AuthService} from "@services/auth/auth.service";

// Constants
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {CityInterface} from "@interfaces/components/cities.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {SearchLocationTypeEnum} from "@interfaces/components/enums/search-location-type.enum";


@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatAutocompleteTrigger,
    MatAutocomplete,
    NgFor,
    MatOption,
    TranslateModule
  ]
})

export class SearchLocationComponent {
  @ViewChild('searchInput') inputSearch!: ElementRef;
  @Input() type: SearchLocationTypeEnum = SearchLocationTypeEnum.FROM_MAP;
  @Input() fullAddress!: string;
  @Input() mobileSearch!: boolean;
  @Input() locations: any;
  @Output() getLocation = new EventEmitter<FullAddressLongLatInterface | null>();
  @Output() searchLocationEmitter = new EventEmitter<string>();
  @Output() closeComponentEmitter = new EventEmitter<void>();

  favoriteAddress!: Array<FavoriteAddressInterface>;
  filteredFavoriteAddressList!: Array<FavoriteAddressInterface>;
  fromSearch!: boolean;
  city!: CityInterface;
  fullAddressObject!: FullAddressLongLatInterface | null;
  searchLocationTypeEnums = SearchLocationTypeEnum;

  constructor(
    private requestService: RequestMethodsService,
    private cityService: CityService,
    private isInGeofenceServices: IsInGeofenceService,
    private authService: AuthService
  ) {
    const city = this.cityService.getCityModelFromLocalStorage();
    if (city && 'city' in city) {
      this.city = city.city;
    }
    if (!this.authService.getUserInfo()) {
      return;
    }
    this.getAllFavoriteAddressList();
  }

  searchAddressAndMakeLongLat(text: string): void {
    if (!text) {
      this.resetAddressSearch();
      return;
    }
    this.filterFavoriteAddressListBySearch(text);
    this.searchLocationEmitter.emit(text);
  }

  filterFavoriteAddressListBySearch(text: string): void {
    if (!this.city) {
      return;
    }
    this.filteredFavoriteAddressList = this.favoriteAddress?.filter((item) => item.fullAddress.includes(text))
  }

  getAllFavoriteAddressList(): void {
    this.requestService.get(`${EndpointConstant.favorite}-${EndpointConstant.addresses}`)
      .subscribe((response: Array<FavoriteAddressInterface>) => {
        this.favoriteAddress = response;
        this.resetAddressSearch();
        this.searchAddressAndMakeLongLat(this.fullAddress)
      })
  }

  resetAddressSearch(): void {
    this.filteredFavoriteAddressList = this.favoriteAddress;
    this.locations = [];
  }

  addressSelectionFavoriteAddress(data: FavoriteAddressInterface): void {
    this.fullAddressObject = {
      fullAddress: data.fullAddress,
      longitude: data.longitude,
      latitude: data.latitude,
      cityName: data.cityName
    }
    this.fromSearch = true;
    this.getLocation.emit(this.fullAddressObject);
  }

  addressSelection(data: any): void {
    const cityName = data.properties._data.metaDataProperty.GeocoderMetaData.Address.Components.find((
      (component: any) => component.kind === 'locality'))
      ?.name || '';

    this.fullAddressObject = this.isInGeofenceServices.fromGeObjectGetLongLatAndAddress(
      data.properties.get('name'), data.geometry._coordinates, cityName
    );
    this.fromSearch = true;
    this.getLocation.emit(this.fullAddressObject)
  }
}
