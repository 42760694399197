import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
// Services
import {ShowMessageService} from "../../messages/show-message.service";
import {AuthService} from "../../auth/auth.service";

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  constructor(
    private showMessageService: ShowMessageService,
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setAuthorizationToken(req)).pipe(catchError((err) => this.errorHandler(err, req, next)));
  }

  errorHandler(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (err.status >= 400) {
      if (err.status === 403) {
        this.showMessageService.showMessageByVariable('permissionError', 'error');
        this.authService.logout();
      } else if (err.status === 401) {
        this.showMessageService.showMessageByVariable('global', 'error');
        this.authService.logout();
      } else if (err.status === 404) {
        this.showMessageService.showMessageByVariable('notFound', 'error');
      } else if (err.error?.errors && Object.keys(err.error?.errors)) {
        if (req.headers.get('error') !== 'false') {
          this.customMessageByTypeLocal(err);
        }
      } else {
        this.showMessageService.showMessageByVariable('global', 'error');
      }
    }

    return throwError(err);
  }

  customMessageByTypeLocal(err: HttpErrorResponse): void {
    const errors = err.error.errors as ({ [key: string]: string });
    const key = Object.keys(errors)[0];
    const value = Object.values(errors)[0];
    this.showMessageService.showCustomMessage((key === 'message' ? '' : (key + ' ')) + value, 'error');
  }

  setAuthorizationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getUser();
    const tokenKey = (request.url.indexOf('renew') > 0) ? 'refreshToken' : 'accessToken';
    if (token?.accessToken) {
      return request.clone({
        setHeaders: {Authorization: `Bearer ${token[tokenKey]}`, 'X-OsType': 'WEB'}
      });
    }
    return request.clone({
      setHeaders: {'X-OsType': 'WEB'}});
  }

}
