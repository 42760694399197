<div class="privacy_policy_container w_100 h_100 mt_35 laptop_padding">
  <app-navigation [navigationData]="[{ label: 'auth.privacy.' + title}]"></app-navigation>
  <div class="privacy_policy_container--body mb_32 pt_50">
    <ng-container *ngIf="!isMobile">
    <span class="privacy_policy_container--body--title text_title_middle pb_35">
     {{'auth.privacy.' + title | translate}}
    </span>
      <div></div>

      <div class="privacy_policy_container--body--info rules">
        <ng-container *ngIf="title=='processing_policy_data_recovery';else secondComponent">
          <app-personal-data></app-personal-data>
        </ng-container>
        <ng-template #secondComponent>
          <app-terms-of-use></app-terms-of-use>
        </ng-template>
      </div>
    </ng-container>
    <div class="position_relative">
      <div class="privacy_policy_container--body--info">
      <span class="bold_700" [ngClass]="[isMobile?'text_title_middle':'text_title']">
        {{'auth.privacy.whole' | translate }} {{'auth.privacy.documentation' | translate | lowercase }}
      </span>

        <div class="privacy_policy_container--body--info--whole_documentation_switch text_normal_sub">

          <div class="first pointer mb_16 mt_40" [ngClass]="{'active_class':isActive=='first'}"
               (click)="changeOrderType('first')">
            {{'auth.privacy.processing_policy_data_recovery' | translate }}
          </div>
          <div class="pointer second" [ngClass]="{'active_class':isActive=='second'}"
               (click)="changeOrderType('second')">
            {{'auth.privacy.terms_of_use' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
