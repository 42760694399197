<div class="address_selection_container flex_column_between br_24">
  <div class="description mt_15">
    {{'navbar.find_best_options' | translate}}
  </div>

  <div class="favorite_addresses_container" [style.max-height]="isMobile ? '100px' : '255px'" >
    <div class="flex_between_align_center delivery_address_radio text_normal_sub bold"
         *ngFor="let elem of favoriteAddress"
    >
      <div class="flex_align_center">
        <mat-radio-button (change)="selectFavoriteAddress(elem)" [checked]="elem.id === addressData.selectedAddress?.id">
        </mat-radio-button>
        <div class="full_address_label">
          {{elem.fullAddress}}
        </div>
      </div>

      <div  class="dropdown flex_center_align_center"
            [matMenuTriggerFor]="menu">
        <img src="assets/images/icons/filter-icon.svg" class="pointer">
        <mat-menu #menu="matMenu">
          <button  mat-menu-item class="flex_align_center" (click)="actionEditOrDelete(actionTypeEnum.TRASH,elem)">
            <img src="assets/images/icons/ic_trash.svg">
            <span class="ml_12">{{'notification.delete' | translate}}</span>
          </button>
        </mat-menu>

      </div>

    </div>
  </div>

  <div class="click_select_new_address flex_align_center mt_30 text_normal_sub bold pointer" (click)="addNewAddress()">
    <div><img src="assets/images/icons/plus.svg"></div>
    <div class="ml_12">{{'navbar.add_new_address' | translate}}</div>
  </div>

  <div class="confirm_address flex_between_align_center w_100 text_normal_sub bold mt_30">
    <div class="flex_align_center">
      <div class="flex_center_align_center"><img src="assets/images/icons/marker-pin-black.svg"></div>
      <div class="ml_12">{{'navbar.check_the_address_with_the_recipient' | translate}}</div>
    </div>
    <div class="toggle">
      <mat-slide-toggle [(ngModel)]="checkAddress"
                        (change)="openCitySelectionDialog()"
      ></mat-slide-toggle>
    </div>
  </div>
  <button class="main_buttons black_btn w_100 big_btn"
          *ngIf="!isMobile"
          [disabled]="disableSave"
          [ngClass]="{'gray_btn': disableSave}"
          (click)="save()">
    {{'buttons.apply'| translate}}
  </button>
</div>

