import {Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgIf, NgClass, NgFor, TitleCasePipe} from '@angular/common';
//constants
import {NavigationInterface} from "@interfaces/components/navigation.interface";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, TranslateModule, TitleCasePipe]
})
export class NavigationComponent {
  @Input()  navigationData!: NavigationInterface[];
}
