import {afterNextRender, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {from} from "rxjs";
//components
import {MapDialogComponent} from "../map-dialog/map-dialog.component";
//services
import {CityService} from "@services/city/city.service";
import {IsInGeofenceService} from "@services/is-in-geofence/is-in-geofence.service";
import {BrowserService} from "@services/components/browser.service";
//constants
import {MapConfigsInterface} from "@interfaces/orders/map-configs.interface";
import {MapTypeEnum} from "@interfaces/orders/enums/map-configs.enum";
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {CityInterface, SimpleCityInterface} from "@interfaces/components/cities.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {TranslateModule} from '@ngx-translate/core';
import {SearchLocationComponent} from '../search-location/search-location.component';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {OrderInfoInterface} from "@interfaces/orders/order-info.interface";
import {BasketService} from "@services/basket/basket.service";
import {Router} from "@angular/router";
import {AuthService} from "@services/auth/auth.service";
import {CheckToken} from "@interfaces/auth/login.interface";
import {IncorrectAddressDialogComponent} from "../incorrect-address-dialog/incorrect-address-dialog.component";
import {response} from "express";

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, SearchLocationComponent, NgClass, TranslateModule]
})
export class MapComponent {
  @ViewChild('searchInput') inputSearch!: ElementRef;
  @ViewChild('mapDiv') mapDiv!: ElementRef

  @Input() set configsForMap(configs: MapConfigsInterface) {
    if (!configs) {
      return;
    }
    this.configs = {...configs}
  };

  @Input() set city(setCity: CityInterface | FavoriteAddressInterface) {
    setCity ? this.longLat.push(+setCity?.latitude, +setCity?.longitude) : this.longLat.push(55.76, 37.64);
    this.cityModel = setCity;
  }

  @Input() favoriteAddress!: Array<FavoriteAddressInterface>;
  @Input() set data(mapDialogData: { type: string, checkOrder: any, defaultAddress: any } | null) {
    if (!mapDialogData) {return}
    this.calledFrom = mapDialogData.type;
    this.checkOrder = mapDialogData.checkOrder;
    if (mapDialogData.defaultAddress?.address) {
      this.defaultAddress = {latitude:  mapDialogData.defaultAddress?.address.latitude, longitude:  mapDialogData.defaultAddress?.address.longitude};
    } else {
      this.defaultAddress = {latitude:  mapDialogData.defaultAddress?.city.latitude, longitude:  mapDialogData.defaultAddress?.city.longitude}
    }
    this.fullAddressObject = mapDialogData.defaultAddress.address;
    this.getDeliveryPriceByLocation(this.defaultAddress)
  }
  @Output() emitOpenMapDialogEventEmitter = new EventEmitter<void>();
  @Output() openConfirmationDialogForAddressEmitter = new EventEmitter<any>();
  @Output() chooseFavoriteAddressEmitter = new EventEmitter<FavoriteAddressInterface>();
  @Output() isMobileMapEmitter = new EventEmitter<FavoriteAddressInterface>()
  calledFrom!: string;
  checkOrder!: OrderInfoInterface;
  openSearchComponent = false;
  configs!: MapConfigsInterface;
  mapConfigsType = MapTypeEnum;
  ymaps: any;
  map: any;
  longLat: Array<number> = [];
  fromSearch!: boolean;
  fullAddressObject!: FullAddressLongLatInterface | null;
  radiusError: boolean = false;
  cityModel!: SimpleCityInterface | FavoriteAddressInterface;
  locations: Array<any> = [];
  favoriteAddressId!: number;
  addressDataObj!: FavoriteAddressInterface;
  defaultAddress!: {latitude: number; longitude: number};
  delivery!:{deliveryPrice: 0} | null
  isUser: CheckToken = CheckToken.INVALID;

  constructor(private mapDialogRef: MatDialogRef<MapDialogComponent>,
              private dialog: MatDialog,
              private cityService: CityService,
              private isInGeofenceService: IsInGeofenceService,
              private browserService: BrowserService,
              private requestService: RequestMethodsService,
              private dialogRef: MatDialogRef<any>,
              private basketServices: BasketService,
              private router: Router,
              private authService: AuthService,
  ) {
    afterNextRender(() => {
      this.ymaps = (window as any)['ymaps'];
      this.displayMap(this.configs);
      this.isUser = this.authService.checkToken();
    })
  }

  displayMap(configs: MapConfigsInterface): void {
    from(this.ymaps.ready()).subscribe(() => {
      this.map = new this.ymaps.Map(configs.id, {
        center: this.longLat,
        zoom: configs.type == this.mapConfigsType.MOBILE ? 9 : 10,
        controls: []
      });
      if (this.calledFrom === "ORDER") {
        if (this.defaultAddress) {
          this.map.setCenter([this.defaultAddress.latitude, this.defaultAddress.longitude]);
        }
        this.checkOrder.polygon.forEach(item => {
          this.addPolygon(item)
        })
      }
      this.setCenterGetLongLat(configs);
      if (configs.type == this.mapConfigsType.EXTRA) {
        this.addControlZoom();
        this.addGeoLocationControl();
      } else if (configs.type == this.mapConfigsType.STANDARD) {
        this.map.behaviors.disable(['scrollZoom', 'drag'])
      }
      if (this.browserService.isMobileDevice || this.browserService.isTabletDevice) {
        this.map.events.add('click', () => this.triggerDivClick());
      }
    });
  }

  triggerDivClick() {
    const divElement: HTMLElement = this.mapDiv.nativeElement;
    const clickEvent = new Event('click', {bubbles: true});
    divElement.dispatchEvent(clickEvent);
  }

  handleNewLocation(data: FullAddressLongLatInterface | null) {
    if (!data) {
      return;
    }
    this.openSearchComponent = false;
    this.fullAddressObject = data;
    this.map.setCenter([data.latitude, data.longitude])
    this.map.setZoom(18);
  }

  openMapDialog(): void {
    if (this.configs.type !== this.mapConfigsType.STANDARD) {
      return;
    }
    this.emitOpenMapDialogEventEmitter.emit();
  }

  addPolygon(item:Array<any>) : void {
    const myGeoObject = new this.ymaps.GeoObject({
      // We describe the geometry of a geo-object.
      geometry: {
        // Geometry type - "Polygon".
        type: "Polygon",
        // We indicate the coordinates of the vertices of the polygon.
        coordinates: [item],
        // We set the rule for filling internal contours using the "nonZero" algorithm.
        fillRule: "nonZero"
      },
      // We describe the properties of a geoobject.
    }, {
      // We describe the geoobject options.
      // Fill color.
      fillColor: 'rgba(255, 66, 48, 0.05)',
      // Outline color.
      strokeColor: 'rgba(255, 66, 48, 0.4)',
      // Overall transparency (for both fill and stroke).
      strokeWidth: 2,
    });

    this.map.geoObjects.add(myGeoObject);
  }

  confirm(): void {
    if (this.delivery?.deliveryPrice === undefined && this.calledFrom === 'ORDER') {
      this.openConfirmDialog()
    } else {
      if (this.browserService.isMobileDevice && this.fullAddressObject) {
        this.isMobileMapEmitter.emit(this.fullAddressObject)
      } else {
        this.dialogRef.close(this.fullAddressObject);
      }
    }
    if (!this.fullAddressObject) {
      return;
    }
    this.addressDataObj = this.fullAddressObject;

    if (this.calledFrom === 'ORDER') { return; }

    if (this.isUser === CheckToken.VALID) {
      this.saveAddressData();
    } else {
      this.closeDialogOrNavigate();
    }
  }

  openConfirmDialog(): void {
    this.dialog.open(IncorrectAddressDialogComponent, {
      width: '500px'
    }).afterClosed().subscribe(res => {
      if (res) {
        this.basketServices.clearBasket().subscribe(() => {
          this.saveAddressData();
          if (this.fullAddressObject) {
            this.cityService.setCityId({address: this.fullAddressObject})
            window.location.href = '/products';
          }
        })
      }
    })
  }

  closeDialogOrNavigate(): void {
    if (this.browserService.isMobileDevice) {
      this.router.navigate(['/products', this.addressDataObj])
    } else {
      this.dialogRef.close(this.addressDataObj)
    }
  }

  saveAddressData(): void {
    let endpoint = `${EndpointConstant.favorite}-${EndpointConstant.addresses}`;
    this.requestService.post(endpoint, this.addressDataObj).subscribe((response:FavoriteAddressInterface) => {
      this.addressDataObj =  {...response}
      this.closeDialogOrNavigate();
    })
  }

  addControlZoom(): void {
    const zoomControl = new this.ymaps.control.ZoomControl({
      options: {
        size: 'small',
        position: {
          bottom: 100,
          right: 34
        }
      },
    });
    this.map.controls.add(zoomControl);
  }

  addGeoLocationControl(): void {
    let geolocationControl = new this.ymaps.control.GeolocationControl({
      options: {
        noPlacemark: true,
        float: 'right',
        size: 'large',
        layout: this.ymaps.templateLayoutFactory.createClass(
          '<div class="custom_geolocation_icon pointer br_16"><img src="assets/images/icons/orders/geolocation.svg"></div>'
        )
      }
    });

    geolocationControl.events.add('locationchange', (event: any) => {
      const position = event.get('position');
      this.map.setCenter(position);
      this.map.setZoom(18)
    });

    this.map.controls.add(geolocationControl);

  }

  setCenterGetLongLat(configs: MapConfigsInterface): void {
    if (configs.type === MapTypeEnum.STANDARD) {
      return;
    }
    this.map.events.add('boundschange', (e: any) => {
      if (this.fromSearch) {
        this.fromSearch = false
        return;
      }

      const newCenter: Array<number> = e.get('newCenter');

      if (this.inputSearch?.nativeElement) {
        this.inputSearch.nativeElement.value = ''
      }

      this.ymaps.geocode(newCenter).then((result: any) => {
        const cityName = result.geoObjects.get(0).properties.get('metaDataProperty')?.GeocoderMetaData?.Address?.Components.find((
          (component: any) => component.kind === 'locality'))
          ?.name || '';
        this.fullAddressObject = this.isInGeofenceService.fromGeObjectGetLongLatAndAddress(
          result.geoObjects.get(0).properties.get('name'), newCenter, cityName
        );

        if (this.calledFrom === 'ORDER') {
          if (!this.fullAddressObject?.latitude || !this.fullAddressObject?.longitude) return;
          const location = {latitude: this.fullAddressObject?.latitude, longitude: this.fullAddressObject?.longitude};
          this.getDeliveryPriceByLocation(location);
        }
        this.mapDiv.nativeElement.click();
      })
    });

  }

  setCenterOnBtnClick(): void {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {latitude, longitude} = position.coords;
        this.map.setCenter([latitude, longitude], 18);

      },
      (error) => {
        console.error('Error getting current position:', error);
      }
    );
  }

  getDeliveryPriceByLocation(location: {latitude: number,longitude: number}) : void {
    this.requestService.post(`${EndpointConstant.orders}/${EndpointConstant.deliveryPrice}`,location).subscribe(response => {
      this.delivery = response;
      this.mapDiv.nativeElement.click();
    })
  }

  searchLocation(searchedAddress: string): void {
    this.ymaps.geocode(searchedAddress).then((res: any) => {
      this.locations = res.geoObjects.toArray().filter((geoObject: any) => {
        const addressDetails = geoObject.properties.get('metaDataProperty').GeocoderMetaData.AddressDetails;
        const countryCode = addressDetails.Country?.CountryNameCode;
        return countryCode === 'RU';
      });
    });
  }

}
