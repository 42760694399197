<div class="mobile_rules_container">
  <div class="privacy_policy_container--body--title text_title  bold_700 pb_35 mt_35">
    {{'auth.privacy.' + title | translate}}
  </div>

  <div class="privacy_policy_container--body--info rules">
    <ng-container *ngIf="title=='processing_policy_data_recovery';else secondComponent">
      <app-personal-data></app-personal-data>
    </ng-container>
    <ng-template #secondComponent>
      <app-terms-of-use></app-terms-of-use>
    </ng-template>
  </div>
</div>
