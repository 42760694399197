import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from '@angular/common/http';

//components
import {NavigationComponent} from "./navigation/navigation.component";
import {ClearButtonComponent} from './clear-button/clear-button.component';
import {AddToFavoritesButtonComponent} from './add-to-favorites-button/add-to-favorites-button.component';
import {ConfirmationDialogComponent} from './notification-dialog/confirmation-dialog.component';
import {InputComponent} from './input/input.component';
import {PersonalDataComponent} from "./privacy-policy/personal-data/personal-data.component";
import {TermsOfUseComponent} from "./privacy-policy/terms-of-use/terms-of-use.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import { SmallScreenComponent } from './small-screen/small-screen.component';
import { MobilePrivacyComponent } from './privacy-policy/mobile-privacy/mobile-privacy.component';
import { StandardBottomSheetComponent } from './standard-bottom-sheet/standard-bottom-sheet.component';

//material components
import {MatSliderModule} from "@angular/material/slider";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    HttpClientModule,
    MatSliderModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    NgxMaskPipe,
    NgxMaskDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDialogModule,
    NavigationComponent,
    ClearButtonComponent,
    AddToFavoritesButtonComponent,
    InputComponent,
    ConfirmationDialogComponent,
    PersonalDataComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    SmallScreenComponent,
    MobilePrivacyComponent,
    StandardBottomSheetComponent
  ],
  providers: [
    provideNgxMask(),
  ],
  exports: [
    NavigationComponent,
    TranslateModule,
    ClearButtonComponent,
    AddToFavoritesButtonComponent,
    InputComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    ConfirmationDialogComponent,
    PrivacyPolicyComponent,
    PersonalDataComponent,
    TermsOfUseComponent,
    SmallScreenComponent
  ]
})
export class GlobalModule {
}
