import {Pipe, PipeTransform} from '@angular/core';
//constants
import {OrderListInterface, OrderReviewStatusEnum, SingleOrderInterface} from "@interfaces/orders/order.interface";
import {orderReviewTextConst, orderReviewTextValue, OrderStatusEnum} from "@interfaces/orders/enums/order-status.enum";

@Pipe({
    name: 'getValueByKeyPipe',
    standalone: true
})
export class GetValueByKeyPipe implements PipeTransform {
  transform(
    data: OrderListInterface | SingleOrderInterface
  ): orderReviewTextValue {
    if (data.status === OrderStatusEnum.ON_THE_WAY || data.status === OrderStatusEnum.DELIVERED) {
      return orderReviewTextConst[OrderReviewStatusEnum.TIMEOUT]
    } else {
      return orderReviewTextConst[data.orderReview.status]
    }
  }
}
