import {afterNextRender, Inject, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
//Services
import {BrowserService} from "@services/components/browser.service";

@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  stateModel = 'stateModelForDownloadApp';
  LocalStorage!: any;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private browserService: BrowserService,
              @Inject(DOCUMENT) private document: Document
  ) {
    afterNextRender(() => {
      this.LocalStorage = document.defaultView?.localStorage;
    })
  }

  scrollToTop(position = 0): void {
    if (this.browserService.isMobileDevice) {
      document.body.scrollTop = position
      document.documentElement.scrollTop = position;
    } else {
      let scroller = document.querySelector('.main') as HTMLElement;
      scroller?.scrollTo({
        top: position,
        behavior: "smooth"
      });
    }
  }

  hideOrShowScrollOfMainComponent(hideOrShow: 'hidden' | 'unset' | 'auto'): void {
    const mainComponent = document.getElementById('main');
    if (!mainComponent) {
      return;
    }
    mainComponent.style.overflowY = hideOrShow
  }
}
