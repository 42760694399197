import {afterNextRender, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Swiper} from "swiper";
import {isPlatformBrowser, NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
//constants
import {ResourceUrlInterface} from "@interfaces/components/resource-url.interface";
import {SwiperTypeEnum} from "@interfaces/components/enums/swiper-type.enum";
//services
import {SwiperService} from "@services/components/swiper.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {Subscription} from "rxjs";
import {BrowserService} from "@services/components/browser.service";

@Component({
  selector: 'app-vertical-swiper',
  templateUrl: './vertical-swiper.component.html',
  styleUrls: ['./vertical-swiper.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor, NgStyle]
})
export class VerticalSwiperComponent implements OnInit {
  @Input('data') set arrayOfImg(arrayOfImage: Array<ResourceUrlInterface>) {
    this.data = arrayOfImage;
    if (this.data.length && isPlatformBrowser(this.platformId)) {
      this.setSwiper();
      this.activeSlide = this.data[0];
    }
  }

  swiper!: Swiper;
  pending = false;
  data!: Array<ResourceUrlInterface>;
  activeSlide!: ResourceUrlInterface;
  isTablet!: boolean;
  isMobile!: boolean;
  // activeIndex !: number;
  // private idChangeSubscription!: Subscription;


  constructor(private swiperService: SwiperService,
              private globalSubscriptionService: GlobalSubscriptionService,
              private browserService: BrowserService,
              @Inject(PLATFORM_ID) private platformId: any
  ) {
    afterNextRender(() => {
      this.isTablet = this.browserService.isTabletDevice;
      this.isMobile = this.browserService.isMobileDevice;
    })
  }

  ngOnInit(): void {
    // this.idChangeSubscription = this.globalSubscriptionService.indexChange$.subscribe(
    //   newId => this.clickToImage(newId)
    // );
  }

  setSwiper(): void {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        slidesPerView:  this.isTablet ? 2 : 4,
        mousewheel: true,
        speed: 500
      });
      const prevButton = document.getElementById('custom-prev-button-vertical');
      const nextButton = document.getElementById('custom-next-button-vertical');
      if (prevButton) {
        prevButton.addEventListener('click', () => {
          this.swiper.slidePrev();
        });
      }
      if (nextButton) {
        nextButton.addEventListener('click', () => {
          this.swiper.slideNext();
        });
      }
      this.pending = true;
    }, 1000)
  }

  clickToImage(index: number): void {
    this.swiper.activeIndex = index;
    const selectedSlide = this.data.find((e: ResourceUrlInterface, i: number) => i === index);
    if (!selectedSlide) {
      return;
    }
    this.activeSlide = selectedSlide;
    this.swiperService.openImgInBiggerView(index, SwiperTypeEnum.SINGLE_PAGE)
  }

  onImageHover(type: 'enter' | 'leave', index?: number): void {
    this.swiperService.openImgInBiggerView(type === 'enter' ? index : this.swiper.activeIndex, SwiperTypeEnum.SINGLE_PAGE)
  }

}
