<div class="slider_actions position_relative"
     [ngClass]="{
      'ml_16': type === swiperTypeEnum.SINGLE_PAGE && !isMobile,
      'opacity' : (type === swiperTypeEnum.SINGLE_PAGE ? !pending : true),
      'product_card': type === swiperTypeEnum.PRODUCT_CARD
     }"
>
  <div class="swiper-container-horizontal-{{type}} flow_hide" #imageContainer
       [ngClass]="{
       'single-page': type === swiperTypeEnum.SINGLE_PAGE,
       'four-columns': type === swiperTypeEnum.PRODUCT_CARD && numOfColumns === 4,
       'three-columns': type === swiperTypeEnum.PRODUCT_CARD && numOfColumns === 3
       }"
  >
    <div class="swiper-wrapper" *ngIf="type === swiperTypeEnum.PRODUCT_CARD; else single">
      <div class="swiper-slide product_card flow_hide br_16 pointer flex_center_align_center"
           *ngFor="let src of img_data"
           (click)="navigateToSinglePageEmitter.emit()"
      >
        <img class="w_100 h_100" [src]="src.resourceUrl">
      </div>
    </div>
    <ng-template #single>
      <div class="swiper-wrapper" *ngIf="img_data?.length">
        <div class="swiper-slide br_40 flow_hide image_w" *ngFor="let elem of img_data">
          <img class="w_100 h_100" [src]="elem.resourceUrl">
        </div>
      </div>
    </ng-template>
    <div class="flex_center_align_center" *ngIf="type !== swiperTypeEnum.SINGLE_PAGE">
      <div class="swiper-pagination swiper-pagination-{{type}}"></div>
    </div>
  </div>
  <!--  <ng-container *ngIf="!isMobile && type === swiperTypeEnum.SINGLE_PAGE ">-->
  <!--    <div id="custom-prev-button-horizontal">-->
  <!--      <img src="assets/images/icons/horizontal1.svg">-->
  <!--    </div>-->
  <!--    <div id="custom-next-button-horizontal">-->
  <!--      <img src="assets/images/icons/horizontal2.svg">-->
  <!--    </div>-->
  <!--  </ng-container>-->
</div>
