import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgxOtpInputConfig, NgxOtpStatus, NgxOtpInputModule} from "ngx-otp-input";
import {NgxMaskPipe} from 'ngx-mask';
import {TranslateModule} from '@ngx-translate/core';
import {NgIf} from '@angular/common';
//Components
import {ErrorMessageComponent} from './error-message/error-message.component';
//Constants
import {TokenInterface} from "@interfaces/auth/login.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
//Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {AuthService} from "@services/auth/auth.service";
import {CityService} from "@services/city/city.service";

declare var ym: Function;

@Component({
    selector: 'app-set-code',
    templateUrl: './set-code.component.html',
    styleUrls: ['./set-code.component.scss'],
    standalone: true,
    imports: [
        NgxOtpInputModule,
        NgIf,
        ErrorMessageComponent,
        TranslateModule,
        NgxMaskPipe,
    ],
})
export class SetCodeComponent implements OnInit {
  @ViewChild('otpInput') otpInput: any;
  @Input() phoneNumber!: string;
  @Input() phoneCode!: string;
  @Output() changeNumberEmitter = new EventEmitter<boolean>();
  @Output() closeLoginDialogAfterSuccess = new EventEmitter<void>();
  @Output() sendCodeAgainEmitter = new EventEmitter<void>();

  second = 59;
  timeToResend = '';
  userInputCode: NgxOtpStatus = null;
  otpInputConfig: NgxOtpInputConfig = {
    numericInputMode: true,
    otpLength: 6,
    classList: {
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  blockInputChange = false;

  constructor(
    private requestService: RequestMethodsService,
    private authService: AuthService,
    private cityService: CityService
  ) {
  }

  ngOnInit(): void {
    this.sendCodeAgain(true)
  }

  inputCode(): void {
    if (this.blockInputChange) {
      return;
    }
    this.userInputCode = null;
  }

  login(code: string): void {
    if (!code) {
      return;
    }

    this.requestService.post('auth', {
      phoneNumber: this.phoneCode + this.phoneNumber,
      code
    }).subscribe((response: TokenInterface) => {
      this.userInputCode = 'success';
      ym(91549733,'reachGoal','registration')
      this.closeLoginDialogAfterSuccess.emit()
      this.authService.setToken(response);
      this.saveFavoriteAddressFromLocal();
    }, () => {
      this.userInputCode = 'error';
      this.blockInputChange = true;
      this.otpInput.clear();
      setTimeout(() => {
        this.blockInputChange = false;
      }, 1500)
    })
  }

  changeNumber() {
    this.changeNumberEmitter.emit(false)
  }

  sendCodeAgain(force = false): void {
    if (this.second > 0 && !force) {
      return;
    }
    this.second = 59;
    let againCodeSendingInterval = setInterval(() => {
      this.second = this.second - 1;
      this.timeToResend = (this.second <= 9) ? '0' + this.second : this.second.toString();
      if (this.second === 0) {
        clearInterval(againCodeSendingInterval)
        this.sendCodeAgainEmitter.emit()
      }
    }, 1000)
  }


  saveFavoriteAddressFromLocal(): void {
    const data = this.cityService.getCityModelFromLocalStorage();
    if (data && 'address' in data) {
      let address = data.address;
      if (address.id) {
        return;
      }
      this.requestService.post(`${EndpointConstant.favorite}-${EndpointConstant.addresses}`, address)
        .subscribe(() => {})
    }
  }
}
