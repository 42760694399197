import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {TranslateModule} from '@ngx-translate/core';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {DecimalPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {MillisecondsToMinutesPipe} from "../../../pipes/date-methodes/milliseconds-to-minutes.pipe";

// Services
import {AuthService} from "@services/auth/auth.service";
import {BasketService} from "@services/basket/basket.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {BrowserService} from "@services/components/browser.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";

// Constants
import {ProductListInterface} from "@interfaces/catalog/products.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {AddAndReduceStatusEnum} from "@interfaces/components/enums/add-and-reduce-status.enum";
import {ConfirmationActionEnum} from "@interfaces/components/enums/confirmation-action.enum";
import {CardEnum} from "@interfaces/catalog/product-card.enum";
import {AddToFavoriteTypeEnum} from "@interfaces/components/enums/add-to-favorite-status.enum";
import {SwiperTypeEnum} from "@interfaces/components/enums/swiper-type.enum";

//Components
import {AddToFavoritesButtonComponent} from '../../global/add-to-favorites-button/add-to-favorites-button.component';
import {AddAndReduceComponent} from '../add-and-reduce/add-and-reduce.component';
import {ProductGalleryComponent} from '../product-gallery/product-gallery.component';
import {StarRateComponent} from "../../global/star-rate/star-rate.component";
import {BonusDisplayComponent} from "../bonus-display/bonus-display.component";

//Pipes
import {PriceSpacesPipe} from "../../../pipes/string-methods/price-spaces.pipe";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  standalone: true,
  imports: [NgIf, ProductGalleryComponent, AddAndReduceComponent, AddToFavoritesButtonComponent, TranslateModule, NgForOf, NgClass, DecimalPipe, MillisecondsToMinutesPipe, StarRateComponent, PriceSpacesPipe, BonusDisplayComponent]
})
export class CardComponent {

  @Input('productCard') set productCardData(data: ProductListInterface) {
    if (!data) {
      return;
    }
    this.productCard = data;
  };

  @Input() type!: CardEnum;
  @Input() componentType!: CardEnum;
  @Input() numOfColumns!: number;
  @Output() deleteFromFavoritesEvent = new EventEmitter<boolean>();
  openCount = false;
  pending = false;
  addAndReduceTypeEnums = AddAndReduceStatusEnum;
  isMobile!: boolean;
  productCard!: ProductListInterface;
  addToFavoriteTypeEnum = AddToFavoriteTypeEnum;
  productCardEnum = CardEnum;
  swiperTypeEnum = SwiperTypeEnum.PRODUCT_CARD;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private basketService: BasketService,
    private globalSubscriptionService: GlobalSubscriptionService,
    private confirmationDialogService: ConfirmationDialogService,
    private browserService: BrowserService,
    private route: ActivatedRoute
  ) {
    this.isMobile = browserService.isMobileDevice;
  }

  getSingleProductInfo(): void {
    const name = CyrillicToTranslit().transform(
      this.productCard.title.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,''), "-"
    ).toLowerCase();
    window.location.href = `/product/${name}/${this.productCard.id}?type=${this.componentType}`;
  }

  addToBasket(event: Event): void {
    event.stopPropagation();
    if (!this.authService.checkTokenAndRedirectToAuth() || this.pending) {
      return;
    }
    if (!this.basketService.checkBasketForShop(this.productCard.shop.id)) {
      this.clearBasketForShop();
      return;
    }

    this.addToBasketMethod()
  }

  addToBasketMethod(force = false): void {
    this.pending = true;
    this.basketService.addBasketItems(this.productCard.id, this.productCard.shop.id).subscribe(() => {
      if (force) {
        this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_CATALOG);
      }
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET);
      this.openCount = true;
      this.productCard.countInBasket = 1;
      this.pending = false;
    }, () => {
      this.pending = false;
    })
  }

  clearBasketForShop(): void {
    this.confirmationDialogService.openConfirmationDialog(
      {
        title: 'notification.clear_bucket',
        content: 'notification.need_to_delete_all_items',
        firstButton: 'notification.back',
        secondButton: 'notification.clear',
      }).afterClosed().subscribe((res: ConfirmationActionEnum) => {
      if (res === ConfirmationActionEnum.APPROVE || !res) {
        return;
      }
      this.basketService.clearBasket().subscribe(() => {
        this.addToBasketMethod(true)
      });
    })
  }

  goToShopDetailsPage(): void {
    const name = CyrillicToTranslit().transform(this.productCard.shop.shopName, "-").toLowerCase();
    let url =`/shop/${name}/${this.productCard.shop.id}`;
    const sectionId = this.route.snapshot.queryParamMap.get('sectionId')
    if (this.componentType===CardEnum.CATALOG && sectionId){
      url+= `?sectionId=${sectionId}`
    }
    window.location.href = url;
  }

  disLike(liked: boolean): void {
    this.productCard.liked = liked
    this.deleteFromFavoritesEvent.emit(true)
  }

}
