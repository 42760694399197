import { Component } from '@angular/core';
import {ClearButtonComponent} from "../../../shared-modules/global/clear-button/clear-button.component";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-incorrect-address-dialog',
  standalone: true,
  imports: [
    ClearButtonComponent
  ],
  templateUrl: './incorrect-address-dialog.component.html',
  styleUrl: './incorrect-address-dialog.component.scss'
})
export class IncorrectAddressDialogComponent {
  constructor(public dialogRef: MatDialogRef<IncorrectAddressDialogComponent>,) {
  }
  close(event: boolean): void {
    this.dialogRef.close(event);
  }
}
