<div class="flower_image">
  <div class="flex_end">
    <app-clear-button (closeButton)="close()"></app-clear-button>
  </div>
  <div class="dialog_container flex_center_align_center mt_24">
    <div class="vertical_slider">
      <app-vertical-swiper [data]="data"></app-vertical-swiper>
    </div>
    <div class="horizontal_slider">
      <app-product-gallery [img_data]="data"></app-product-gallery>
    </div>
  </div>
</div>
