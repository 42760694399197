<div class="footer_container">
  <div class="top_footer" [ngClass]="{'big_padding' : add_big_padding}">
    <div class="container hide_on_mobile_only">
      <section>
        <div class="item">
          <h3 class="text_title_36">{{'footer.download_our_app' | translate}}</h3>
          <div class="flex_column bold text_normal_sub bold">
            <span>{{'footer.info' | translate}}</span>
            <span>{{'footer.available_on_all_devices' | translate}}</span>
          </div>
        </div>
        <div class="item">
          <div class="mobile_phone flex_center_align_center">
            <img src="assets/images/icons/footer/phone.png" class="w_100 h_100">
          </div>
        </div>
      </section>
      <section>
        <div class="app_qr_grid">
          <div class="app_icons_container">
            <div class="application_icon pointer flex_center_align_center br_16"
                 *ngFor="let link of downloadLinksConfig"
                 (click)="downloadApplicationLink(link.link)">
              <img [src]="link.image">
            </div>
          </div>
          <div class="qr_code pointer">
            <img class="br_32 w_100 h_100" src="assets/images/icons/footer/qr-code1.svg">
          </div>
        </div>
      </section>
    </div>
    <section class="privacy_and_social_links flex_between_align_center pt_28">
      <div class="privacy_policy bold">
        <span class="pointer" (click)="navigateToPrivacyPolicyPage()">Публичная оферта</span>
        <span class="pointer" (click)="navigateToPrivacyPolicyPage()">{{'footer.processing_policy' | translate}}</span>
      </div>

      <div class="social_links middle_small_text">
        <ng-container *ngFor="let social of socialLinks">
          <img class="pointer" [src]="social.image" (click)="navigateTpSocialLink(social.link)">
        </ng-container>
      </div>
    </section>
    <div class="container show_on_mobile_only">
      <div class="static_text text_center">
        <h3>{{'footer.download_our_app' | translate}}</h3>
        <div class="second_text bold text_normal bold mt_16">
          {{'footer.info' | translate}}<br>
          {{'footer.available_on_all_devices' | translate}}
        </div>
      </div>
      <div class="app_icons_container mt_24">
        <div class="application_icon pointer"
             *ngFor="let link of downloadLinksConfig"
             (click)="downloadApplicationLink(link.link)">
          <div class="image_container">

            <img [src]="link.image">
          </div>
        </div>
        <div class="grid_item mt_24">
          <div class="flex_column bold middle_small_text pt_24 text_center">
            <span class="pointer mb_16"
                  (click)="navigateToPrivacyPolicyPage()">{{'footer.processing_policy' | translate}}</span>
            <span class="pointer"
                  (click)="navigateToPrivacyPolicyPage()">{{'footer.privacy_of_cite' | translate}}</span>
          </div>
        </div>
        <div class="social_links middle_small_text mt_32">
          <ng-container *ngFor="let social of socialLinks">
            <img class="pointer" [src]="social.image" (click)="navigateTpSocialLink(social.link)">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom_footer flex_align_center">
    <div class="privacy_policy--rights container middle_small_text bold">
      {{"footer.privacy_policy" | translate}}
    </div>
  </div>
</div>
