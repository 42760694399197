<div class="search_logo_container flex_between_align_center" *ngIf="!open">
  <div class="search_icon br_50 flex_center_align_center">
    <img src="assets/images/icons/navbar/search.svg" class="pointer" (click)="openOrCloseSearchComponent(true)">
  </div>
  <div>
    <img src="assets/images/mobile_icons/logo.svg">
  </div>
  <div class="bonus_system pointer" (click)="openBonusInfoDialog()">
    <div class="bonus_and_text flex_end_align_center br_24">
        <span class="text font_15 bold_800">
              {{(bonusAmount || 0) | priceSpaces}}
            </span>
      <img src="assets/images/icons/navbar/bonus_icon.svg">
    </div>
  </div>
</div>

<div *ngIf="open">
  <app-search-dialog (closeSearchComponentEmitter)="openOrCloseSearchComponent(false)"></app-search-dialog>
</div>
