<div class="bonus_system_container br_24 flex_center_align_center"
     [ngClass]="{'next_to': nextTo}"
     [ngStyle]="{'flex-direction': type === fromComponentTypeEnum.SHOP ? 'row-reverse': 'row'}">
  <span class="bold_800" [ngClass]="[type === fromComponentTypeEnum.BASKET ? 'text_normal': 'font_17']">
    <span *ngIf="type !== fromComponentTypeEnum.SHOP && !discount">+</span>
    <span [class.red_color]="discount" *ngIf="discount">-</span>
    <span [class.red_color]="discount">{{bonus | priceSpaces}}</span>
    <span *ngIf="type === fromComponentTypeEnum.SHOP">%</span>
  </span>
  <img [src]="discount ? 'assets/images/icons/navbar/bonus_red_icon.svg' : 'assets/images/icons/navbar/bonus_icon.svg'"  [ngStyle]="type === fromComponentTypeEnum.SHOP ? {'margin-right': '4px'} : {'margin-left': '2px'}">
</div>
