<div class="basket_main_container">
  <div class="basket_section flex_center_align_center"
       [ngClass]="{'w_100': (basketType === basketTypeStatusEnums.ORDER_BASKET) || (basketType === basketTypeStatusEnums.MOBILE_BASKET)}"
       *ngIf="basket?.basketItems?.length; else mobileTitle">
    <div class="basket_container">
      <div class="basket_container--header flex_between"
           [ngClass]="{'display_none':(basketType === basketTypeStatusEnums.ORDER_BASKET) && isMobile, 'pl_pr_16' : isMobile}">
        <div [ngClass]="[isMobile ? 'text_title_middle' :'text_title_middle']">
          {{'navbar.basket.title' | translate}}
        </div>
        <div class="basket_container--header--clear_button flex_center_align_center text_normal_sub bold pointer"
             (click)="clearBasket()" *ngIf="basket?.basketItems?.length">
          {{'navbar.basket.clear' | translate}}
        </div>
      </div>

      <ng-container *ngIf="basket?.basketItems?.length">
        <div class="basket_container--orders_details mt_24"
             [ngClass]="{'scroll': (basketType === basketTypeStatusEnums.NAVBAR_BASKET),
         'display_none':(basketType === basketTypeStatusEnums.ORDER_BASKET) && isMobile}">
          <div class="basket_container--orders_details--order_info_block flex_between_align_center"
               [ngClass]="{'tablet_order_basket' : isTablet && basketType== basketTypeStatusEnums.ORDER_BASKET}"
               *ngFor="let elem of basket.basketItems">
            <div class="flex_align_center">
              <div class="image_w" *ngIf="elem.productShop.resourceUrls">
                <img class="w_100 h_100" [src]="elem.productShop.resourceUrls[0].resourceUrl">
              </div>
              <div class="ml_20 title_info flex_column_between">
                <div class="text_normal bold_600 name">
                  {{elem.productShop?.title}}
                </div>
                <div class="count text_title bold_800 flex_align_center">
                  <span>{{elem.productShop.price | priceSpaces}} ₽</span>
                  <app-bonus-display [bonus]="elem.bonusAmount"
                                     [type]="fromComponentTypeEnum.BASKET"
                                     [nextTo]="true"></app-bonus-display>
                </div>
              </div>
            </div>
            <div [ngClass]="{'align_self_center':isTablet && basketType== basketTypeStatusEnums.ORDER_BASKET }">
              <app-add-and-reduce [productCard]="elem.productShop"
                                  [type]="addAndReduceTypeEnums.ORDER_CREATION"></app-add-and-reduce>
            </div>
          </div>
        </div>
        <div class="basket_container--description"
             [ngClass]="{'position_fixed':basketType === basketTypeStatusEnums.MOBILE_BASKET,
                         'pl_pr_16': basketType === basketTypeStatusEnums.ORDER_BASKET && isMobile}"
        >
          <div class="basket_container--description--more-info br_20"
               [ngClass]="{'mt_16':!isMobile}"
               *ngIf="basketType === basketTypeStatusEnums.ORDER_BASKET">
            <div *ngFor="let item of basket.basketItems" class="flex_between_align_center">
              <span class="product_title text_normal bold">
                {{item.productShop.title}}
              </span>
              <span class="product_price bold_800 text_normal mt_8">
                {{ (item.productShop.countInBasket ?
                (item.productShop.price * item.productShop.countInBasket) :
                item.productShop.price) | priceSpaces}} ₽
              </span>
            </div>

            <div class="flex_between_align_center mt_8"
                 *ngIf="basket.deliveryPrice != null"
            >
              <span class="product_title text_normal bold">{{'orders.delivery' | translate}}</span>
              <div class="bold_800 text_normal"
                   [ngClass]="{'check_with_recipient middle_small_text': basket.deliveryPrice == null}">
                {{(basket.deliveryPrice === 0 ? ('orders.delivery_free' | translate) : (basket.deliveryPrice + ' ₽')) | priceSpaces}}
              </div>
            </div>

            <div class="flex_between_align_center mt_8" *ngIf="basket.discountByPromoCode">
               <span class="product_title text_normal bold">
                 {{'orders.discount_by_promo' | translate}} {{promoCode}}
               </span>
              <span class="bold_800 text_normal red_color">-{{basket.discountByPromoCode | priceSpaces}} ₽</span>
            </div>

            <div class="flex_between_align_center mt_8" *ngIf="basket.totalBonusAmount">
               <span class="product_title text_normal bold">
                 {{'orders.bonus_for_order' | translate}}
               </span>
              <app-bonus-display [type]="fromComponentTypeEnum.BASKET"
                                 [bonus]="basket.totalBonusAmount"
                                 [nextTo]="true"
              ></app-bonus-display>
            </div>
            <div class="flex_between_align_center mt_8" *ngIf="basket.discountByBonus">
               <span class="product_title middle_small_text bold">
                 {{'orders.discount_by_bonus' | translate}}
               </span>
              <app-bonus-display [type]="fromComponentTypeEnum.BASKET"
                                 [discount]="true"
                                 [nextTo]="true"
                                 [bonus]="basket.discountByBonus"></app-bonus-display>
            </div>
          </div>

          <div class="check_with_recipient text_normal_sub flex_between_align_center mt_8 br_20"
               *ngIf="basket.deliveryPrice == null && basketType === basketTypeStatusEnums.ORDER_BASKET">
            <span class="text_gray_color  bold">{{'orders.delivery' | translate}}:</span>
            <span class="bold_800 red_color">{{'orders.check_recipient' | translate}}</span>
          </div>

          <div class="basket_container--description--total br_20 flex_between_align_center"
               [ngClass]="[basketType === basketTypeStatusEnums.NAVBAR_BASKET ? 'margin_28': 'mt_8']"
               *ngIf="(isMobile && basketType === basketTypeStatusEnums.ORDER_BASKET) || !isMobile"
          >
            <span class="bold_700 "
                  [ngClass]="[isMobile? 'font_17' : 'text_normal_sub']"> {{'navbar.basket.total' | translate}}</span>
            <span class="bold_800" [ngClass]="[isMobile? 'font_17' : 'text_title']">{{basket.totalPrice | priceSpaces}}
              ₽</span>
          </div>

          <button class="main_buttons w_100 black_btn text_normal_sub bold pointer"
                  [ngClass]="[isMobile ? 'with_padding br_12' : 'br_20']"
                  *ngIf="basketType === basketTypeStatusEnums.NAVBAR_BASKET || basketType === basketTypeStatusEnums.MOBILE_BASKET"
                  (click)="checkout()">
            <span [class.flex_column_center]="isMobile">
              <span class="text_normal_sub" *ngIf="!isMobile;else mobileDesign">
              {{'navbar.basket.checkout' | translate}}
              </span>
              <ng-template #mobileDesign>
                <span>
                  {{'navbar.basket.checkout_mobile' | translate}}
                </span>
                <span class="total_count">Всего {{basket.basketItems.length}} товара</span>
              </ng-template>
            </span>

          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-template #mobileTitle>
    <div class="text_title_middle bold_700 pl_pr_16" *ngIf="isMobile && !basket?.basketItems?.length">
      {{'navbar.basket.title' | translate}}
    </div>
  </ng-template>

</div>
