<div class="slider-container">
  <button class="arrow prev pointer flex_center_align_center"
          *ngIf="showLeftArrow" (click)="scrollLeftFn()">
    <img src="assets/images/icons/sections/chevron-right.svg">
  </button>
  <div class="slider" #slider>
    <ng-content></ng-content>
  </div>
  <button class="arrow next pointer flex_center_align_center"
          *ngIf="showRightArrow" (click)="scrollRightFn()">
    <img src="assets/images/icons/sections/chevron-right.svg">
  </button>
</div>
