<div class="phone_number_container">
  <div class="title">
    <div class="enter_phone_number bold_700 text_normal pb_40">
      {{'auth.please_enter_phone_number' | translate}}
    </div>
  </div>
  <div class="input_number_container mt_35 flex_column" [formGroup]="form">
    <div class="phone_number_container flex_align_center br_16 text_normal">
      <div class="num bold">+7</div>
      <div class="input_number">
        <input maxlength="15" formControlName="phone" type="text"
               placeholder="{{'input_placeholder.phone_number' | translate}}"
               mask="(000)-000-00-00">
      </div>
    </div>

    <div class="flex mt_18">
      <label class="checkbox">
        <input type="checkbox" formControlName="checked">

        <span class="checkmark" [ngClass]="{checked:form.controls.checked.value}">
          <img src="assets/images/icons/check.svg">
        </span>
      </label>
      <div class="ml_25">
        <span>{{'auth.privacy.accept' | translate}}</span>
        <span class="pointer" (click)="openPrivacyPolicy()">
          <span class="bold_700 ml_2">{{'auth.privacy.privacy_of_cite' | translate}}</span>
          <span class="ml_2">{{'and' | translate}}</span>
          <span class="bold_700 ml_2">{{'auth.privacy.policy' | translate}}</span>
        </span>
      </div>
    </div>

    <button type="submit" class="get_code pointer br_16 mt_30"
            [disabled]="form.invalid || submit"
            [ngClass]="{'disabled': form.invalid || submit}"
            (click)="phoneLogIn()">{{'auth.get_code' | translate}}
    </button>
  </div>

</div>
