import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CityInterface, CityPaginationInterface} from "@interfaces/components/cities.interface";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, NgFor } from '@angular/common';
import {BasketService} from "@services/basket/basket.service";
import {
  IncorrectAddressDialogComponent
} from "../../../address-selection/incorrect-address-dialog/incorrect-address-dialog.component";
import {CityService} from "@services/city/city.service";
import {AuthService} from "@services/auth/auth.service";

@Component({
    selector: 'app-city-drop-down',
    templateUrl: './city-drop-down.component.html',
    styleUrls: ['./city-drop-down.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, NgFor, TranslateModule]
})
export class CityDropDownComponent {
  @Input() set data(data: {selected: number, isMobile: boolean, type: string }) {
    this.cityData = data;
    this.getCities();
  }

  searchedValue = '';
  arrayOfSearchedCity!: Array<CityInterface>;

  constructor(
    public dialogRef: MatDialogRef<CityDropDownComponent>,
    private requestService: RequestMethodsService,
    private basketService: BasketService,
    private dialog: MatDialog,
    private cityService: CityService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public cityData: {selected: number, isMobile: boolean, type: string}) {
    this.getCities()
  }

  city!: CityInterface;
  selectedCityId!: number;

  getCities(): void {
    const searchedValue = this.searchedValue ? `&search=${this.searchedValue}` : '';
    this.requestService.get(`${EndpointConstant.cities}/${EndpointConstant.all}?page=1&size=100${searchedValue}`
    ).subscribe((response: CityPaginationInterface) => {
      this.arrayOfSearchedCity = response.content.filter(item=>item.name);
    }, (error) => console.log(error))
  }

  selectCity(city: CityInterface): void {
    if (!this.authService.getUser()) {
      this.cityData.isMobile ? this.city = city : this.dialogRef.close(city);
      this.cityService.setCityId({city: this.city})
      window.location.href = '/products';
      return;
    }
    this.basketService.checkBasketAvailabilityForNewAddress({city: city}).subscribe(res => {
      if (!res) {
        this.openConfirmDialog(city)
      } else {
        this.cityData.isMobile ? this.city = city : this.dialogRef.close(city);
        if (this.cityData.type !== 'ORDER') {
          this.cityService.setCityId({city: this.city});
          window.location.href = '/products';
        }
      }
    })
  }

  openConfirmDialog(city: CityInterface): void {
    this.dialog.open(IncorrectAddressDialogComponent, {
      width: '500px'
    }).afterClosed().subscribe(res => {
      if (res) {
        this.basketService.clearBasket().subscribe(() => {
          this.cityData.isMobile ? this.city = city : this.dialogRef.close(city);
          if (city) {
              this.cityService.setCityId({city: city})
              window.location.href = '/products';
          }
        })
      }
    })
  }

  changeSelected() {
    this.cityData.selected = this.selectedCityId
  }

  getData(): any {
    return this.city
  }

}
