<div class="mobile_menu">
  <div *ngIf="totalFavoriteElements" class="circle flex_center_align_center br_50 small_text">
    {{totalFavoriteElements}}
  </div>
  <div class="bg_color">
    <div class="container">
      <div class="menu w_100 flex_around_align_center text_normal ">
        <div class="menu--navigations--link--item pointer flex_column_center" *ngFor="let item of pages"
             [ngClass]="{'mobile_active_route': item.isActive === true }"
             (click)="navigateTo(item.route)">
          <img [src]="item.isActive ==true ? 'assets/images/mobile_icons/menu/'+item.icon+'-active.svg'
                                             :'assets/images/mobile_icons/menu/'+item.icon+'.svg'">
          <span class="mt_8">{{item.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
