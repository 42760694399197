<div class="laptop_padding bg_color">
  <div class="container">
    <div class="navbar w_100 text_normal flex_between_align_center">
      <img class="pointer logo flex_center_align_center" src="assets/images/logo.svg" (click)="navigateCatalog()">
      <div class="search app_input w_100" [ngClass]="{'focus_in':searchBy}">
        <div class="icons_container">
          <ng-container *ngIf="searchBy">
            <img
              (click)="searchBy = ''"
              src="assets/images/icons/navbar/x-close.svg"
              class="pointer">
            <div class="border"></div>
          </ng-container>
          <img
            [src]="searchBy ? 'assets/images/icons/navbar/search-active.svg':'assets/images/icons/navbar/search.svg'"
          >
        </div>
        <input type="text"
               #searchInput
               [value]="searchBy || ''"
               placeholder="{{'input_placeholder.find_favorite_flowers' | translate}}"
               (keyup)="searchFor(searchInput.value)"
        >
        <div class="search_dialog w_100" *ngIf="searchBy" #searchMenu>
          <app-search-dialog [searchBy]="searchBy"></app-search-dialog>
        </div>

      </div>

      <div class="routes_and_basket_container w_100 text_normal ">
        <div class="bonus_container flex_column_center_align_center w_100 h_100 pointer" (click)="openBonusInfoDialog()">
          <div class="bonus_and_text flex_center_align_center br_24">
            <img src="assets/images/icons/navbar/bonus_icon_white.svg">
            <span class="text font_15 bold">
              {{bonusAmount || 0}}
            </span>
          </div>
          <div class="mt_3 font_15 bold title">{{'navbar.bonus' | translate}}</div>
        </div>
        <div class="basket_container position_relative">
          <div *ngIf="basketData?.basketItems?.length"
               class="circle flex_center_align_center br_50">
          </div>
          <div class="flex_column_center"
               [ngClass]="{pointer: basketData?.basketItems?.length}"
               (mouseenter)="onMouseEnter()"
               (mouseleave)="onMouseOut()">
            <img src="assets/images/icons/navbar/basket.svg">
            <span class="mt_4 font_15 bold color_gray">{{'navbar.basket.title' | translate}}</span>
          </div>

          <div class="basket_block"
               *ngIf="isUser"
               [ngClass]="{hide_basket: !open}" (mouseleave)="onMouseOut()"
               (mouseenter)="onMouseEnter()">
            <app-basket [type]="basketTypeEnum.NAVBAR_BASKET"
                        (updatedBasketInfo)="basketData = $event"
            ></app-basket>
          </div>
        </div>

        <div class="route_item pointer flex_column_center" *ngFor="let item of pages"
             [ngClass]="{'active': item.isActive === true }"
             (click)="navigateTo(item.route)">
          <img [src]="item.isActive ==true ? 'assets/images/icons/navbar/'+item.icon+'-active.svg'
                                             :'assets/images/icons/navbar/'+item.icon+'.svg'">
          <span class="mt_4 font_15 bold color_gray">{{item.title | translate}}</span>
        </div>
      </div>

    </div>
  </div>
</div>

<div [ngClass]="{'show':searchBy}"
     (click)="searchBy=''" class="backdrop_for_search"></div>
