import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'makePaginationCountArray',
    standalone: true
})
export class MakePaginationCountArrayPipe implements PipeTransform {

  transform(value: number): Array<number> {
    const pages: Array<number> = [];
    (new Array(value)).fill(0).forEach((item, key) => pages.push(key + 1));
    return pages;
  }

}
