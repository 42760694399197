<div class="mobile_header">
  <app-top-navbar *ngIf="navigationIcon === navigationBackButtonTypeEnum.CATALOG"></app-top-navbar>
  <app-bottom-navbar [selectedData]="cityControl"
                     [navigationIcon]="navigationIcon"
                     (selectCityEvent)="openDialog()"
                     (selectAddressToDeliverEvent)="openDialogOrBottomSheetAddressSelect($event)"
  ></app-bottom-navbar>
  <app-show-default-address-to-change
    *ngIf="defaultAddressIsSelected"
    (closeDialog)="handleDialogClose($event)"
  ></app-show-default-address-to-change>
</div>

<app-mobile-map *ngIf="openComponentForMobileMap"
                (closeMapComponentEmitter)="closeMobileMap($event)"
>
</app-mobile-map>
